<template>
  <v-container class="container--fluid grid-list-md">
    <DocumentNumberConfirmation
      :confirmDocumentNumberDialog.sync="confirmDocumentNumberDialog"
    />
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1"
          >{{ $t("delivery.deliveryActions") }}</span
        >
        <NextAndPreviousCommon
          :nextId="nextId"
          :prevId="prevId"
          :documentId="deliveryId"
          @idChange="redirectOnNextAndPrevious"
        />
      </v-col>
    </v-row>
    <v-row
      no-gutters
      v-if="deliveryId && !!model.is_locked && model.user_id !== user.id"
    >
      <v-col cols="12" class="my-3">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1 error--text"
          >*{{
            $t("common.documentReadOnlyModeNotice", { userId: model.user.name })
          }}</span
        >
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <v-card-title class="py-1">
            <h5
              v-if="originModel.delivery_note_document_number"
              style="max-width: 175px"
              :class="
                deliveryId
                  ? 'px-1 text-truncate font-italic primary--text font-weight-bold'
                  : 'primary--text text--darken-1'
              "
              class="font-weight-regular"
            >
              {{
                deliveryId && !originModel.delivery_company
                  ? `${originModel.delivery_note_document_number}.`
                  : $t("delivery.addDelivery") || deliveryId
                  ? originModel.delivery_company &&
                    `${originModel.delivery_note_document_number}. ${originModel.delivery_company}`
                  : $t("delivery.addDelivery")
              }}
            </h5>
            <v-spacer />
            <v-menu
              bottom
              v-if="deliveryId"
              :close-on-click="true"
              :offset-y="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="
                    remarksList && remarksList.length > 0
                      ? 'success'
                      : 'primary'
                  "
                  class="ma-2"
                  outlined
                  small
                  @click="remarksDialog = true"
                  :disabled="
                    accessRight.length < 2 && accessRight.includes('show')
                  "
                >
                  {{ $t("remarks.remarks") }}
                </v-btn>
                <v-btn
                  color="primary"
                  class="ma-2"
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    !valid || (!!model.is_locked && model.user_id !== user.id)
                  "
                >
                  <v-icon left> mdi-menu-down </v-icon>
                  {{ $t("quotation.options") }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  dense
                  class="primary--text"
                  :disabled="
                    !valid ||
                    model.status == 0 ||
                    (!!model.is_locked && model.user_id !== user.id)
                  "
                  @click="saveDeliveryCreateInvoice('ReturnCertificate')"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-cloud-upload-outline
                      </v-icon>
                      {{ $t("returnCertificate.createReturnCertificate") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="accessRight.includes('create')"
                  dense
                  class="primary--text"
                  @click="copyDelivery"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-content-copy
                      </v-icon>
                      {{ $t("common.copyDocument") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  class="primary--text"
                  @click="actDialog = true"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text"> mdi-pulse </v-icon>
                      {{ $t("quotation.activityLog") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  class="primary--text"
                  @click="attachedDocumentsDialog = true"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-file-document
                      </v-icon>
                      {{ $t("employee.attachedDocuments") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  :disabled="selectedStatus == 2"
                  v-if="accessRight.includes('delete')"
                  class="error--text"
                  @click="
                    (confirmationDialogStatus = true),
                      (delete_item = deliveryId)
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="error--text">
                        mdi-delete-sweep-outline
                      </v-icon>
                      {{ $t("common.delete") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu
              bottom
              v-if="deliveryId"
              :close-on-click="true"
              :offset-y="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="ma-2"
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    !valid || (!!model.is_locked && model.user_id !== user.id)
                  "
                >
                  <v-icon left> mdi-menu-down </v-icon>
                  {{ $t("calendar.calendarEvents") }}
                </v-btn>
              </template>
              <v-list>
                <!-- <LoginGoogle /> -->
                <CommonCalendar
                  :docId="deliveryId"
                  docType="Delivery"
                  :model="model"
                />
              </v-list>
            </v-menu>
            <v-btn
              v-if="deliveryId && accessRight.includes('create')"
              color="primary"
              class="me-2"
              small
              outlined
              :disabled="
                !valid ||
                model.status == 0 ||
                (!!model.is_locked && model.user_id !== user.id)
              "
              @click="saveDeliveryCreateInvoice('Invoice')"
            >
              <v-icon left> mdi-file-download-outline </v-icon>
              {{ $t("delivery.createInvoice") }}
            </v-btn>
            <HtmlToPdf
              v-if="deliveryId"
              class="me-2 mb-1"
              :disabled="
                deliveryItems[0].delivery_note_id &&
                valid &&
                !(!!model.is_locked && model.user_id !== user.id)
              "
              :model="originModel"
              type="DeliveryPDF"
            />
            <v-btn
              color="primary"
              small
              :loading="loading"
              :disabled="
                !valid ||
                (isFieldReadable && !saveOnlyCategoryStatus) ||
                (!!model.is_locked && model.user_id !== user.id) ||
                (accessRight.length < 2 && accessRight.includes('show'))
              "
              @click="saveDelivery"
            >
              {{ $t("delivery.save") }}
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="text-h5 pa-2">
            <FormSkeleton
              v-if="
                (deliveryId && Object.keys(deliveryById).length <= 0) ||
                (copiedDelivery && copyFormLoading)
              "
            />
            <v-form
              v-else
              ref="form"
              v-model="valid"
              lazy-validation
              :disabled="accessRight.length < 2 && accessRight.includes('show')"
            >
              <Alert :alert.sync="alert" :message="message" />
              <v-row>
                <v-col cols="6">
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary">mdi-account-group-outline</v-icon>
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("delivery.customerDetail") }}
                      </h6>
                      <v-spacer />
                      <v-icon
                        v-if="customer_id"
                        @click="openCustomerAdditionalDetailModel"
                        color="primary"
                        class="float-end"
                        >mdi-eye-outline</v-icon
                      >
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-combobox
                            class="asterisk"
                            autocomplete="new-password"
                            dense
                            cache-items
                            :readonly="isFieldReadable"
                            v-model="selectedCustomer"
                            :items="customerList.data"
                            item-text="name_1"
                            item-value="id"
                            @change="onCustomerChange"
                            flat
                            :rules="customerValid"
                            return-object
                            hide-no-data
                            hide-details="auto"
                            required
                            single-line
                            @focus="
                              documentNextAndPrevious(originModel.customer_id)
                            "
                            @blur="documentNextAndPreviousBlur()"
                            @dblclick="redirectOnEditCustomerPage"
                            :search-input.sync="customerSearch"
                            :label="$t('delivery.field.customer')"
                          >
                            <template v-slot:append-outer>
                              <v-icon
                                color="primary"
                                dense
                                v-if="!isFieldReadable"
                                @click="customerDialog = true"
                              >
                                mdi-magnify
                              </v-icon>
                            </template>
                            <template v-slot:selection="data">
                              <span @dblclick="redirectOnEditCustomerPage">{{
                                `${data.item.id}. ${data.item.name_1}`
                              }}</span>
                            </template>
                            <template v-slot:item="data">
                              <div
                                class="font-weight-medium"
                                style="
                                  font-size: 0.8125rem;
                                  display: flex;
                                  align-item: center;
                                "
                              >
                                {{ `${data.item.id}. ${data.item.name_1}` }}
                              </div>
                            </template>
                            <template v-slot:append-item>
                              <div
                                v-show="hasNextPage"
                                v-intersect="infiniteScroll"
                                ref="load"
                                class="loader text-center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                />
                              </div>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            class="asterisk"
                            autocomplete="new-password"
                            hide-details="auto"
                            :readonly="isFieldReadable"
                            v-model="model.customer_name"
                            :label="$t('delivery.field.customerName')"
                            :rules="customerNameValid"
                            required
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            hide-details="auto"
                            :readonly="isFieldReadable"
                            v-model="model.address"
                            :label="$t('delivery.field.address')"
                            :rules="[
                              lessThanValidation(
                                $t('delivery.field.address'),
                                $t('common.less'),
                                250,
                                model.address
                              ),
                            ]"
                            required
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            hide-details="auto"
                            :readonly="isFieldReadable"
                            v-model="model.town"
                            :label="$t('delivery.field.town')"
                            :rules="[
                              lessThanValidation(
                                $t('delivery.field.town'),
                                $t('common.less'),
                                100,
                                model.town
                              ),
                            ]"
                            required
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary"
                        >mdi-file-delimited-outline</v-icon
                      >
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("delivery.deliveryDetail") }}
                      </h6>
                      <v-spacer />
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-text-field
                            hide-details="auto"
                            :readonly="isFieldReadable"
                            dense
                            v-model="model.delivery_company"
                            :label="$t('delivery.field.title')"
                            :rules="titleValid"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            hide-details="auto"
                            :readonly="isFieldReadable"
                            v-model="model.customer_order_no"
                            :label="$t('delivery.field.customerOrderNo')"
                            :rules="customerOrderNoValid"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-menu
                            v-if="!isFieldReadable"
                            ref="startDateMenu"
                            v-model="startDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="deliveryDateFormat"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                v-model="model.delivery_date"
                                :label="$t('delivery.field.deliveryDate')"
                                @blur="
                                  deliveryDateFormat = profileById.date_format
                                    ? parseDateYYYYMMDD(model.delivery_date)
                                    : model.delivery_date
                                "
                                v-bind="attrs"
                                v-on="on"
                                readonly
                              ></v-text-field>
                              <!-- {{ model.delivery_date }} -->
                            </template>
                            <v-date-picker
                              v-model="deliveryDateFormat"
                              :min="minDate"
                              :max="maxDate"
                              @change="
                                $refs.startDateMenu.save(deliveryDateFormat)
                              "
                            >
                              <v-btn
                                small
                                class="primary"
                                @click="$refs.startDateMenu.save(todayDate())"
                                >{{ $t("route.today") }}</v-btn
                              >
                            </v-date-picker>
                          </v-menu>
                          <v-text-field
                            v-else
                            hide-details="auto"
                            v-model="model.delivery_date"
                            :label="$t('delivery.field.deliveryDate')"
                            readonly
                          />
                        </v-col>
                        <v-col cols="4">
                          <label
                            v-if="!isFieldReadable"
                            style="font-size: 81%"
                            v-text="$t('delivery.field.deliveryTime')"
                          />
                          <vue-timepicker
                            v-if="!isFieldReadable"
                            :disabled="
                              accessRight.length < 2 &&
                              accessRight.includes('show')
                            "
                            :placeholder="$t('delivery.field.hour')"
                            v-model="model.delivery_time"
                            enableRtl="true"
                          >
                            <template #clearButton>
                              <v-icon
                                :class="locale === 'he' ? 'hebrewposition' : ''"
                                >mdi-close</v-icon
                              >
                            </template>
                          </vue-timepicker>
                          <v-text-field
                            v-else
                            readonly
                            hide-details="auto"
                            v-model="model.delivery_time"
                            :label="$t('delivery.field.deliveryTime')"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-combobox
                            ref="contactCombo"
                            autocomplete="new-password"
                            :readonly="isFieldReadable"
                            v-model="selectedContact"
                            :items="contactList.data"
                            item-text="name"
                            item-value="id"
                            @change="onContactChange"
                            :clearable="!isFieldReadable"
                            flat
                            return-object
                            hide-no-data
                            hide-details="auto"
                            required
                            single-line
                            :search-input.sync="contactSearch"
                            :label="$t('delivery.field.contact')"
                          >
                            <template v-slot:append-outer>
                              <v-icon
                                class="me-1 mt-1"
                                color="primary"
                                dense
                                v-if="!isFieldReadable"
                                @click="contactDialog = true"
                              >
                                mdi-magnify
                              </v-icon>
                              <v-icon
                                class="mt-1"
                                color="primary"
                                dense
                                v-if="
                                  accessRight.includes('create') &&
                                  customer_id &&
                                  !isFieldReadable
                                "
                                @click="dialog = true"
                              >
                                mdi-plus
                              </v-icon>
                            </template>
                            <template v-slot:item="data">
                              <div
                                class="font-weight-medium"
                                style="
                                  font-size: 0.8125rem;
                                  display: flex;
                                  align-item: center;
                                "
                              >
                                {{ `${data.item.id}. ${data.item.name}` }}
                              </div>
                            </template>
                            <template v-slot:append-item>
                              <div
                                v-show="hasContactNextPage"
                                v-intersect="infiniteScrollOnContact"
                                ref="load"
                                class="loader text-center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                />
                              </div>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="4">
                          <v-combobox
                            autocomplete="new-password"
                            :readonly="isFieldReadable"
                            v-model="selectedEmployee"
                            :items="employeeList.data"
                            item-text="name"
                            item-value="id"
                            @change="onEmployeeChange"
                            :clearable="!isFieldReadable"
                            flat
                            return-object
                            hide-no-data
                            hide-details="auto"
                            required
                            single-line
                            @dblclick="redirectOnEditEmployeePage"
                            :search-input.sync="employeeSearch"
                            :label="$t('delivery.field.agent')"
                          >
                            <template v-slot:append-outer>
                              <v-icon
                                class="mt-1"
                                color="primary"
                                dense
                                v-if="!isFieldReadable"
                                @click="employeeDialog = true"
                              >
                                mdi-magnify
                              </v-icon>
                            </template>
                            <template v-slot:item="data">
                              <div
                                class="font-weight-medium"
                                style="
                                  font-size: 0.8125rem;
                                  display: flex;
                                  align-item: center;
                                "
                              >
                                {{ `${data.item.id}. ${data.item.name}` }}
                              </div>
                            </template>
                            <template v-slot:append-item>
                              <div
                                v-show="hasEmployeeNextPage"
                                v-intersect="infiniteScrollOnEmployee"
                                ref="load"
                                class="loader text-center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                />
                              </div>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            hide-details="auto"
                            readonly
                            v-model="model.year"
                            :label="$t('delivery.field.year')"
                          />
                        </v-col>
                      </v-row>
                      <v-row :dense="true">
                        <v-col
                          cols="4"
                          v-if="moreDocumentNumber && !deliveryId"
                        >
                          <v-autocomplete
                            :disabled="isDeliveryId"
                            hide-details="auto"
                            cache-items
                            :items="documentData"
                            :key="documentData.length"
                            item-text="from_no"
                            item-value="id"
                            flat
                            :rules="!isDeliveryId ? documentNumberRequired : []"
                            v-model="model.doc_series_number"
                            return-object
                            hide-no-data
                            required
                            single-line
                            :label="$t('documentSeries.selectNumber')"
                          >
                            <template v-slot:item="data">
                              <span>{{
                                `${data.item.series} - ${data.item.from_no}. ${data.item.description}`
                              }}</span>
                            </template>
                            <template v-slot:selection="data">
                              <span>{{
                                `${data.item.series} - ${data.item.from_no}. ${data.item.description}`
                              }}</span>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                          <WarehouseInput
                            :isFieldReadable="isFieldReadable"
                            :selectedWarehouseModel.sync="selectedWarehouse"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-row dense no-gutters>
                        <v-col cols="7" class="d-flex">
                          <v-icon color="primary">mdi-cart-plus</v-icon>
                          <h6
                            class="ms-2 align-self-center font-weight-regular"
                          >
                            {{ $t("delivery.listOfItems") }}
                          </h6>
                        </v-col>
                        <v-col cols="3" class="text-end font-weight-regular">
                          <v-text-field
                            hide-details="auto"
                            dense
                            v-model="selectedOrder"
                            readonly
                            :clearable="!isFieldReadable"
                            clear-icon="mdi-close"
                            @click:clear="onOrderClear"
                            :label="$t('delivery.field.order')"
                            @dblclick="redirectOnEditOrderClickPage"
                          >
                            <template v-slot:append-outer>
                              <v-icon
                                class="mt-1"
                                color="primary"
                                dense
                                v-if="!isFieldReadable"
                                @click="orderDialog = true"
                              >
                                mdi-magnify
                              </v-icon>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="2" class="text-end font-weight-regular">
                          <SuppliedQuantityDialog
                            :id="deliveryId"
                            @suppliedQty="suppliedQty"
                          />
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-simple-table
                            dense
                            class="custom-table elevation-1 item-table"
                            fixed-header
                            height="250"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr
                                  v-if="
                                    !!selectedOrder || !!selectedProformaInvoice
                                  "
                                >
                                  <th
                                    :style="{ width: '20px' }"
                                    v-if="shouldShowCheckboxes()"
                                  >
                                    <v-checkbox
                                      :readonly="isFieldReadable"
                                      x-small
                                      @click="selectAllOrderProduct"
                                      v-model="allSelectedProduct"
                                      dense
                                      hide-details
                                    />
                                  </th>
                                  <th :style="{ width: '40px' }">
                                    {{ $t("delivery.no") }}
                                  </th>
                                  <th :style="{ width: '220px' }">
                                    {{ $t("delivery.product") }}
                                  </th>
                                  <th :style="{ width: '180px' }">
                                    {{ $t("delivery.itemDescription") }}
                                  </th>
                                  <th :style="{ width: '50px' }">
                                    {{ $t("delivery.quantity") }}
                                  </th>
                                  <th
                                    v-if="
                                      !deliveryId ||
                                      (deliveryId &&
                                        accessRight.includes('financial'))
                                    "
                                    class="text-center"
                                    :style="{ width: '100px' }"
                                  >
                                    {{ $t("delivery.unitPrice") }}
                                  </th>
                                  <th
                                    v-if="
                                      !deliveryId ||
                                      (deliveryId &&
                                        accessRight.includes('financial'))
                                    "
                                    :style="{ width: '50px' }"
                                  >
                                    {{ $t("delivery.lineDiscount") }}
                                  </th>
                                  <th
                                    v-if="!deliveryId"
                                    class="text-center"
                                    :style="{ width: '110px' }"
                                  >
                                    <span
                                      v-if="
                                        ($route.query.orderId &&
                                          orderById.including_vat) ||
                                        ($route.query.proformaId &&
                                          proformaById.including_vat)
                                      "
                                    >
                                      {{
                                        $t("company.field.totalIncludingVAT")
                                      }}
                                    </span>
                                    <span
                                      v-else-if="
                                        ($route.query.orderId &&
                                          !orderById.including_vat) ||
                                        ($route.query.proformaId &&
                                          !proformaById.including_vat)
                                      "
                                    >
                                      {{ $t("delivery.total") }}
                                    </span>
                                    <span v-else-if="!profileById.delivery">
                                      {{ $t("delivery.total") }}
                                    </span>
                                    <span v-else-if="profileById.delivery">
                                      {{
                                        $t("company.field.totalIncludingVAT")
                                      }}
                                    </span>
                                  </th>
                                  <th
                                    v-else
                                    class="text-center"
                                    :style="{ width: '110px' }"
                                  >
                                    {{
                                      !model.including_vat
                                        ? $t("delivery.total")
                                        : $t("company.field.totalIncludingVAT")
                                    }}
                                  </th>
                                  <th
                                    v-if="
                                      !!selectedOrder ||
                                      !!selectedProformaInvoice
                                    "
                                    class="text-center"
                                    :style="{ width: '80px' }"
                                  >
                                    {{ $t("delivery.action") }}
                                  </th>
                                </tr>
                                <tr v-else-if="!selectedOrder">
                                  <th :style="{ width: '40px' }">
                                    {{ $t("delivery.no") }}
                                  </th>
                                  <th :style="{ width: '220px' }">
                                    {{ $t("delivery.product") }}
                                  </th>
                                  <th :style="{ width: '180px' }">
                                    {{ $t("delivery.itemDescription") }}
                                  </th>
                                  <th :style="{ width: '50px' }">
                                    {{ $t("delivery.quantity") }}
                                  </th>
                                  <th
                                    v-if="
                                      !deliveryId ||
                                      (deliveryId &&
                                        accessRight.includes('financial'))
                                    "
                                    class="text-center"
                                    :style="{ width: '100px' }"
                                  >
                                    {{ $t("delivery.unitPrice") }}
                                  </th>
                                  <th
                                    v-if="
                                      !deliveryId ||
                                      (deliveryId &&
                                        accessRight.includes('financial'))
                                    "
                                    :style="{ width: '50px' }"
                                  >
                                    {{ $t("delivery.lineDiscount") }}
                                  </th>
                                  <th
                                    v-if="!deliveryId"
                                    class="text-center"
                                    :style="{ width: '100px' }"
                                  >
                                    <span
                                      v-if="
                                        ($route.params.copiedModel &&
                                          $route.params.copiedModel
                                            .including_vat) ||
                                        ($route.query.quotationId &&
                                          quotationById.including_vat) ||
                                        ($route.query.orderId &&
                                          orderById.including_vat) ||
                                        ($route.query.proformaId &&
                                          proformaById.including_vat) ||
                                        ($route.query.invoiceId &&
                                          invoiceById.including_vat) ||
                                        ($route.query.receiptTaxInvoiceId &&
                                          receiptTaxInvoiceById.including_vat) ||
                                        ($route.query.creditInvoiceId &&
                                          creditInvoiceById.including_vat) ||
                                        ($route.query.returnCertificateId &&
                                          returnCertificateById.including_vat)
                                      "
                                    >
                                      {{
                                        $t("company.field.totalIncludingVAT")
                                      }}
                                    </span>
                                    <span
                                      v-else-if="
                                        ($route.params.copiedModel &&
                                          !$route.params.copiedModel
                                            .including_vat) ||
                                        ($route.query.quotationId &&
                                          !quotationById.including_vat) ||
                                        ($route.query.orderId &&
                                          !orderById.including_vat) ||
                                        ($route.query.proformaId &&
                                          !proformaById.including_vat) ||
                                        ($route.query.invoiceId &&
                                          !invoiceById.including_vat) ||
                                        ($route.query.receiptTaxInvoiceId &&
                                          !receiptTaxInvoiceById.including_vat) ||
                                        ($route.query.creditInvoiceId &&
                                          !creditInvoiceById.including_vat) ||
                                        ($route.query.returnCertificateId &&
                                          !returnCertificateById.including_vat)
                                      "
                                    >
                                      {{ $t("delivery.total") }}
                                    </span>
                                    <span v-else-if="!profileById.delivery">
                                      {{ $t("delivery.total") }}
                                    </span>
                                    <span v-else-if="profileById.delivery">
                                      {{
                                        $t("company.field.totalIncludingVAT")
                                      }}
                                    </span>
                                  </th>
                                  <th
                                    v-else
                                    class="text-center"
                                    :style="{ width: '100px' }"
                                  >
                                    {{
                                      !model.including_vat
                                        ? $t("delivery.total")
                                        : $t("company.field.totalIncludingVAT")
                                    }}
                                  </th>
                                  <th
                                    class="text-center"
                                    :style="{ width: '80px' }"
                                  >
                                    {{ $t("delivery.action") }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <template
                                  v-for="(item, index) in deliveryItems"
                                >
                                  <template
                                    v-if="
                                      index === 0 ||
                                      item.same_doc_id !==
                                        deliveryItems[index - 1].same_doc_id
                                    "
                                  >
                                    <tr
                                      v-if="item.source_document_number"
                                      :key="'new_line_' + item.same_doc_id"
                                      :style="'background-color: #CFD8DC'"
                                    >
                                      <td
                                        :colspan="
                                          selectedOrder ||
                                          selectedProformaInvoice
                                            ? 2
                                            : 1
                                        "
                                      ></td>
                                      <td><span>-</span></td>
                                      <td colspan="6">
                                        <span>
                                          <b>{{ $t("order.field.docNo") }}:</b>
                                          {{ item.source_document_number
                                          }}<br />
                                        </span>
                                        <span>
                                          <b
                                            >{{ $t("order.field.docDate") }}:</b
                                          >
                                          {{
                                            item.source_document_date.split(
                                              "T"
                                            )[0]
                                          }}<br />
                                        </span>
                                        <span v-if="item.source_document_title">
                                          <b
                                            >{{
                                              $t("order.field.docTitle")
                                            }}:</b
                                          >
                                          {{ item.source_document_title }}<br />
                                        </span>
                                        <span
                                          v-if="
                                            item.source_document_customer_order_no
                                          "
                                        >
                                          <b
                                            >{{
                                              $t("order.field.customerOrderNo")
                                            }}:</b
                                          >
                                          {{
                                            item.source_document_customer_order_no
                                          }}<br />
                                        </span>
                                      </td>
                                    </tr>
                                  </template>
                                  <tr
                                    style="vertical-align: bottom"
                                    :key="index"
                                    :class="[
                                      'yourRowClass',
                                      index + 1 === deliveryItems.length
                                        ? 'newRow'
                                        : '',
                                      {
                                        disabled:
                                          (selectedOrder ||
                                            selectedProformaInvoice) &&
                                          !model.id,
                                      },
                                      item.item_code === '*' &&
                                      item.doc_discount
                                        ? 'yellow accent-2'
                                        : '',
                                      {
                                        disabled:
                                          item.item_code === '*' &&
                                          item.doc_discount,
                                      },
                                    ]"
                                  >
                                    <td v-if="shouldShowCheckboxes()">
                                      <v-checkbox
                                        :readonly="isFieldReadable"
                                        x-small
                                        @click="selectedDeliveryProduct"
                                        v-model="selectedProduct"
                                        :value="item"
                                        @change="checkBoxEventCalculate(item)"
                                        dense
                                        hide-details
                                      />
                                    </td>
                                    <td>
                                      {{ index + 1 }}
                                    </td>
                                    <td>
                                      <span
                                        v-if="!isFieldReadable"
                                        class="d-flex"
                                      >
                                        <product-item-select
                                          :selectedCustomer="selectedCustomer"
                                          :table="'DeliveryNote'"
                                          :key="index"
                                          :itemId="item.item_id"
                                          :item="item"
                                          :index="index"
                                          @onProductChange="onProductChange"
                                        />
                                        <template>
                                          <v-icon
                                            class="mt-1"
                                            color="primary"
                                            dense
                                            v-if="!isFieldReadable"
                                            @click="
                                              (productDialog = true),
                                                (productDialogIndex = index)
                                            "
                                            :disabled="
                                              accessRight.length < 2 &&
                                              accessRight.includes('show')
                                            "
                                          >
                                            mdi-magnify
                                          </v-icon>
                                        </template>
                                      </span>
                                      <span
                                        v-else
                                        @dblclick="
                                          redirectOnEditProductPage(
                                            item.item_id
                                          )
                                        "
                                        >{{ item.item_code }}</span
                                      >
                                    </td>
                                    <td>
                                      <v-textarea
                                        v-if="!item.doc_discount"
                                        dense
                                        rows="1"
                                        :readonly="isFieldReadable"
                                        hide-details="auto"
                                        v-model="item.item_description"
                                        :title="item.item_description"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="[
                                          lessThanValidation(
                                            $t('delivery.itemDescription'),
                                            $t('common.less'),
                                            4000,
                                            item.item_description
                                          ),
                                        ]"
                                        required
                                      />
                                      <v-textarea
                                        v-else
                                        dense
                                        rows="1"
                                        :readonly="isFieldReadable"
                                        hide-details="auto"
                                        :placeholder="
                                          $t('common.documentDiscount')
                                        "
                                      />
                                    </td>
                                    <td>
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        hide-details="auto"
                                        v-model="item.quantity"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="quantityValid"
                                        required
                                        @input="itemPriceCalculation(index)"
                                      >
                                        <template v-if="item.quantity === 0">
                                          {{ (item.quantity = null) }}
                                        </template>
                                      </v-text-field>
                                    </td>
                                    <td
                                      v-if="
                                        !deliveryId ||
                                        (deliveryId &&
                                          accessRight.includes('financial'))
                                      "
                                    >
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        :name="'unit_price' + index"
                                        :ref="'unit_price' + index"
                                        :reverse="
                                          locale === 'he' ? false : true
                                        "
                                        hide-details="auto"
                                        v-model="item.unit_price"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="unitPriceValid"
                                        required
                                        @input="itemPriceCalculation(index)"
                                      >
                                        <template v-if="item.unit_price === 0">
                                          {{ (item.unit_price = null) }}
                                        </template>
                                      </v-text-field>
                                    </td>
                                    <td
                                      v-if="
                                        !deliveryId ||
                                        (deliveryId &&
                                          accessRight.includes('financial'))
                                      "
                                    >
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        hide-details="auto"
                                        v-model="item.line_discount"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="discountValid"
                                        required
                                        @input="itemPriceCalculation(index)"
                                      >
                                        <template v-slot:append>
                                          <v-icon
                                            class="mt-2"
                                            x-small
                                            color="primary"
                                            >mdi-percent-outline</v-icon
                                          >
                                        </template>
                                        <template
                                          v-if="item.line_discount === 0"
                                        >
                                          {{ (item.line_discount = null) }}
                                        </template>
                                      </v-text-field>
                                    </td>
                                    <td
                                      v-if="
                                        !deliveryId ||
                                        (deliveryId &&
                                          accessRight.includes('financial'))
                                      "
                                      :class="
                                        locale === 'he'
                                          ? 'text-start'
                                          : 'text-end'
                                      "
                                    >
                                      <v-text-field
                                        v-if="item.item_code == '*'"
                                        dense
                                        :readonly="isFieldReadable"
                                        :name="'total_price' + index"
                                        :ref="'total_price' + index"
                                        :reverse="
                                          locale === 'he' ? false : true
                                        "
                                        hide-details="auto"
                                        v-model="item.linediscount_total"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="unitPriceValid"
                                        required
                                        @input="itemPriceCalculation(index)"
                                      >
                                        <template v-if="item.total === 0">
                                          {{ (item.total = null) }}
                                        </template>
                                      </v-text-field>
                                      <v-text-field
                                        v-else
                                        dense
                                        :readonly="isFieldReadable"
                                        :name="'total_price' + index"
                                        :ref="'total_price' + index"
                                        :reverse="
                                          locale === 'he' ? false : true
                                        "
                                        hide-details="auto"
                                        v-model="item.total"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="unitPriceValid"
                                        required
                                        @input="itemPriceCalculation(index)"
                                      >
                                        <template v-if="item.total === 0">
                                          {{ (item.total = null) }}
                                        </template>
                                      </v-text-field>
                                    </td>
                                    <td class="text-center">
                                      <v-row>
                                        <v-col cols="4" class="text-end">
                                          <v-btn
                                            icon
                                            :disabled="
                                              isFieldReadable ||
                                              (accessRight.length < 2 &&
                                                accessRight.includes('show'))
                                            "
                                            color="primary"
                                            small
                                            @click="addRow"
                                          >
                                            <v-icon color="primary">
                                              mdi-plus
                                            </v-icon>
                                          </v-btn>
                                        </v-col>
                                        <v-col cols="4" class="text-end">
                                          <v-btn
                                            icon
                                            :disabled="
                                              isFieldReadable ||
                                              (accessRight.length < 2 &&
                                                accessRight.includes('show')) ||
                                              !item.selectedProduct
                                            "
                                            color="primary"
                                            x-small
                                            class="mt-1"
                                            @click="copyData(index)"
                                          >
                                            <v-icon color="primary">
                                              mdi-content-copy
                                            </v-icon>
                                          </v-btn>
                                        </v-col>
                                        <v-col cols="4" class="text-start">
                                          <v-btn
                                            icon
                                            :disabled="
                                              isFieldReadable ||
                                              (accessRight.length < 2 &&
                                                accessRight.includes('show'))
                                            "
                                            color="error"
                                            small
                                            @click="deleteRow(index)"
                                          >
                                            <v-icon color="error">
                                              mdi-delete-sweep-outline
                                            </v-icon>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                      <v-row :dense="true" class="pt-6">
                        <v-col cols="8">
                          <v-row :dense="true">
                            <v-col cols="8">
                              <v-row>
                                <v-col cols="6">
                                  <v-radio-group
                                    hide-no-data
                                    hide-details="auto"
                                    dense
                                    single-line
                                    v-model="selectedStatus"
                                    :readonly="isFieldReadable"
                                    class="text-caption pt-0 mt-3"
                                  >
                                    <template v-slot:label>
                                      <div>
                                        {{ $t("delivery.field.status") }}
                                      </div>
                                    </template>
                                    <v-radio
                                      v-for="statusItem in statusList"
                                      :key="statusItem.id"
                                      :label="`${statusItem.name}`"
                                      :value="statusItem.id"
                                    ></v-radio>
                                  </v-radio-group>
                                  <v-select
                                    v-model="selectedGroup"
                                    :items="groupValue"
                                    item-text="description"
                                    item-value="id"
                                    return-object
                                    :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                    }"
                                    :label="$t('delivery.field.deliveryStatus')"
                                    @change="changeCategoryStatus"
                                  />
                                  <v-text-field
                                    hide-details="auto"
                                    dense
                                    :readonly="isFieldReadable"
                                    v-model="model.car_no"
                                    :label="$t('delivery.field.carNo')"
                                    :rules="carnoValid"
                                  />
                                </v-col>
                                <v-col cols="6">
                                  <v-autocomplete
                                    v-if="
                                      relatedProformaInvoicesOrderItems.length >
                                      0
                                    "
                                    :class="
                                      relatedProformaInvoicesOrderItems.length >
                                      0
                                        ? 'accent lighten-3'
                                        : ''
                                    "
                                    cache-items
                                    :items="relatedProformaInvoicesOrderItems"
                                    item-text="related_model_id"
                                    item-value="related_model_id"
                                    @change="redirectOnEditOrderPage"
                                    flat
                                    return-object
                                    hide-no-data
                                    hide-details="auto"
                                    required
                                    single-line
                                    :label="$t('delivery.field.orderNo')"
                                  />
                                  <v-text-field
                                    v-else
                                    :class="
                                      selectedOrder ? 'accent lighten-3' : ''
                                    "
                                    hide-details="auto"
                                    v-model="selectedOrder"
                                    readonly
                                    :label="$t('delivery.field.orderNo')"
                                    @dblclick="
                                      redirectOnEditOrderPage(selectedOrder)
                                    "
                                  />
                                  <v-text-field
                                    :class="
                                      selectedProformaInvoice
                                        ? 'accent lighten-3'
                                        : ''
                                    "
                                    hide-details="auto"
                                    v-model="selectedProformaInvoice"
                                    readonly
                                    :label="$t('proformaPdf.customerInvoiceNo')"
                                    @dblclick="redirectOnEditProformaPage()"
                                  />
                                  <v-autocomplete
                                    v-if="relatedQuotationsItems.length > 0"
                                    :class="
                                      relatedQuotationsItems.length > 0
                                        ? 'accent lighten-3'
                                        : ''
                                    "
                                    cache-items
                                    :items="relatedQuotationsItems"
                                    item-text="related_model_id"
                                    item-value="related_model_id"
                                    @change="redirectOnEditQuotationPage"
                                    flat
                                    return-object
                                    hide-no-data
                                    hide-details="auto"
                                    required
                                    single-line
                                    :label="$t('delivery.field.quotationNo')"
                                  />
                                  <v-text-field
                                    v-else
                                    :class="
                                      model.quotation_id
                                        ? 'accent lighten-3'
                                        : ''
                                    "
                                    hide-details="auto"
                                    readonly
                                    v-model="model.quotation_id"
                                    :label="$t('delivery.field.quotationNo')"
                                    @dblclick="
                                      redirectOnEditQuotationPage(
                                        model.quotation_id
                                      )
                                    "
                                  />
                                  <v-autocomplete
                                    :class="
                                      relatedInvoiceItems.length > 0
                                        ? 'accent lighten-3'
                                        : ''
                                    "
                                    cache-items
                                    :items="relatedInvoiceItems"
                                    item-text="related_doc_number"
                                    item-value="related_doc_number"
                                    @change="redirectOnEditInvoicePage"
                                    return-object
                                    hide-no-data
                                    hide-details="auto"
                                    required
                                    single-line
                                    :label="$t('delivery.field.invoiceNo')"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="4">
                              <v-textarea
                                hide-details="auto"
                                rows="6"
                                :readonly="isFieldReadable"
                                v-model="model.remarks"
                                :label="$t('delivery.field.remarks')"
                                :rules="[
                                  lessThanValidation(
                                    $t('delivery.field.remarks'),
                                    $t('common.less'),
                                    250,
                                    model.remarks
                                  ),
                                ]"
                              />
                            </v-col>
                          </v-row>
                          <v-row :dense="true"> </v-row>
                        </v-col>
                        <v-divider class="mx-8" vertical />
                        <v-col
                          cols="3"
                          v-if="
                            !deliveryId ||
                            (deliveryId && accessRight.includes('financial'))
                          "
                        >
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">
                              {{ $t("delivery.total") }}
                            </v-col>
                            <v-col
                              cols="6"
                              class="font-weight-bold"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.total) }}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6">
                              <v-row dense no-gutters>
                                <v-col cols="6">
                                  {{ $t("delivery.field.discount") }}
                                </v-col>
                                <v-col cols="6">
                                  <v-text-field
                                    height="18"
                                    class="ma-0 pa-0"
                                    hide-details="auto"
                                    dense
                                    :readonly="isFieldReadable"
                                    :rules="discountValid"
                                    @input="deliveryPriceCalculation"
                                    v-model="model.discount_percentage"
                                  >
                                    <template v-slot:append>
                                      <v-icon x-small color="primary"
                                        >mdi-percent-outline</v-icon
                                      >
                                    </template>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col
                              cols="6"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.discount) }}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">
                              {{ $t("delivery.field.afterDiscount") }}
                            </v-col>
                            <v-col
                              cols="6"
                              class="font-weight-bold"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.after_discount) }}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6">
                              <v-row dense no-gutters>
                                <v-col cols="6">
                                  {{ $t("delivery.field.vat") }}
                                </v-col>
                                <v-col cols="6">
                                  <v-text-field
                                    :disabled="includeVatCharge ? false : true"
                                    height="18"
                                    :readonly="isFieldReadable"
                                    class="ma-0 pa-0"
                                    hide-details="auto"
                                    dense
                                    :rules="vatPrecentageValid"
                                    @input="deliveryPriceCalculation"
                                    v-model="model.vat_percentage"
                                  >
                                    <template v-slot:append>
                                      <v-icon x-small color="primary"
                                        >mdi-percent-outline</v-icon
                                      >
                                    </template>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col
                              cols="6"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.vat) }}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">
                              {{ $t("delivery.field.grandTotal") }}
                            </v-col>
                            <v-col
                              cols="6"
                              class="font-weight-bold"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.grand_total) }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-overlay :z-index="999" v-if="isProgressCircular">
        <v-icon size="64" class="hourglass-icon" color="primary"
          >mdi-timer-sand-paused</v-icon
        >
      </v-overlay>
      <ActivityLogTableDialog
        :actDialog.sync="actDialog"
        :docId.sync="deliveryId"
        docType="DeliveryNote"
        docMode="sales"
        docItems="DeliveryNoteItem"
        docEmailSent="DeliveryEmailSent"
      />
      <ContactDialog :dialog.sync="dialog" :customer_id.sync="customer_id" />
      <ProductDialog
        @onProductChange="onProductChange"
        v-if="customer_id"
        :dialog.sync="productDialog"
        :productDialogIndex="productDialogIndex"
      />
      <CustomerDetail
        :model.sync="customerAdditionalDetail"
        :isFieldReadable="isFieldReadable"
        @saveCustomerAdditionalDetail="saveCustomerAdditionalDetail"
        :customerDetailDialog.sync="customerDetailDialog"
      />
      <EmployeeDialog
        @onEmployeeChange="onEmployeeChange"
        :dialog.sync="employeeDialog"
      />
      <CustomerDialog
        @onCustomerChange="onCustomerChange"
        :dialog.sync="customerDialog"
      />
      <ContactListDialog
        v-if="customer_id"
        @onContactChange="onContactChange"
        :dialog.sync="contactDialog"
        :customerId.sync="customer_id"
      />
      <OrderDialog
        :dialog.sync="orderDialog"
        v-if="customer_id"
        @onOrderChange="onOrderChange"
        :customer_id.sync="customer_id"
        :selectedCustomer.sync="selectedCustomer"
      />
      <status-confirmation-dialog
        :dialog.sync="confirmationDialog"
        :setOrderStatus.sync="setOrderStatus"
        @changeStatus="saveStatusEventWithDelivery"
      />
      <RemarksDialog
        :dialog.sync="remarksDialog"
        :status="originModel.status"
        :docId="deliveryId"
        :docType="30"
      />
      <AttachedDocumentsDialog
        :docId="deliveryId"
        docType="Delivery"
        :dialog.sync="attachedDocumentsDialog"
      />
      <confirmation-dialog
        :dialog.sync="confirmationDialogStatus"
        :delete_item.sync="delete_item"
        @deleteItem="deleteItem"
      />
      <PeriodClosureConfirmationDialog
        :dialog.sync="periodClosureConfirmationDialog"
        :periodClosureConfirmation="periodClosureConfirmation"
      />
      <CommonCopyAndCreateDialog
        v-if="copyAndCreateDialog"
        :dialog.sync="copyAndCreateDialog"
        :modelData="model"
        type="Delivery"
      />
      <SuppliedDialog :dialog.sync="suppliedDialogBox" />
      <RemainingSupplied
        :dialog.sync="remainingSuppliedDialogBox"
        :supplied="model.deliveryNoteItems"
        doc="delivery"
        :docId="model.id"
        :model="model"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import {
  changedFormData,
  parseDateYYYYMMDD,
  formatDateDDMMYYYY,
  todayDate,
  getAuxiliaryZtableValueByName,
} from "@/utils";
import FormSkeleton from "@/components/skeleton/FormSkeleton";
import Alert from "@/components/Alert";
import ContactDialog from "../customer/Steps/ContactDialog.vue";
import ProductDialog from "@/components/moduleTableDialog/Dialog/ProductDialog";
import ProductItemSelect from "../../../components/ProductItemSelect.vue";
import CustomerDialog from "@/components/moduleTableDialog/Dialog/CustomerDialog";
import ActivityLogTableDialog from "@/components/activityLog/Dialogs/ActivityLogTableDialog.vue";
import CustomerDetail from "@/components/moduleTableDialog/Dialog/CustomerDetail";
import EmployeeDialog from "@/components/moduleTableDialog/Dialog/EmployeeDialog";
import ContactListDialog from "@/components/moduleTableDialog/Dialog/ContactListDialog";
import OrderDialog from "./Dialog/OrderDialog";
import HtmlToPdf from "@/components/generatePDF/HtmlToPdf";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import StatusConfirmationDialog from "@/components/StatusConfirmationDialog";
import RemarksDialog from "@/components/RemarksDialog";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import AttachedDocumentsDialog from "@/components/AttachedDocumentsDialog";
import DocumentNumberConfirmation from "@/components/DocumentNumberConfirmation";
import PeriodClosureConfirmationDialog from "@/components/PeriodClosureConfirmationDialog";
import CommonCopyAndCreateDialog from "@/components/CommonCopyAndCreateDialog";
import SuppliedDialog from "@/components/SupliedDialog";
import RemainingSupplied from "@/components/RemainingSuppliedDialog";
import NextAndPreviousCommon from "@/components/NextAndPreviousCommon";
import SuppliedQuantityDialog from "@/components/SuppliedQuantityDialog";
import WarehouseInput from "@/components/WarehouseInput";
import CommonCalendar from "@/components/CommonCalendar";
import { validationMixin } from "@/mixins/validationMixin";
import { intersectionMixin } from "@/mixins/intersectionMixin";
import { searchMixin } from "@/mixins/searchMixin";

export default {
  name: "DeliveryAction",
  components: {
    FormSkeleton,
    Alert,
    ContactDialog,
    ProductDialog,
    CustomerDetail,
    ActivityLogTableDialog,
    EmployeeDialog,
    CustomerDialog,
    ContactListDialog,
    OrderDialog,
    HtmlToPdf,
    VueTimepicker,
    ProductItemSelect,
    StatusConfirmationDialog,
    RemarksDialog,
    AttachedDocumentsDialog,
    ConfirmationDialog,
    DocumentNumberConfirmation,
    PeriodClosureConfirmationDialog,
    CommonCopyAndCreateDialog,
    SuppliedDialog,
    RemainingSupplied,
    NextAndPreviousCommon,
    SuppliedQuantityDialog,
    WarehouseInput,
    CommonCalendar,
  },
  mixins: [validationMixin, intersectionMixin, searchMixin],
  data() {
    return {
      message: "",
      modeltotalstore: 0.0,
      customerDetailDialog: false,
      alert: false,
      loading: false,
      valid: false,
      confirmationDialog: false,
      deliveryDateFormat: "",
      fromSaveButton: true,
      setOrderStatus: null,
      customerAdditionalDetail: {},
      model: {
        status: 0,
        total: 0.0,
        discount: 0.0,
        after_discount: 0.0,
        vat: 0.0,
        grand_total: 0.0,
        delivery_company: "",
      },
      originModel: {},
      lastSaleDate: false,
      lastPurchaseDate: false,
      selectedCustomer: null,
      customerDialog: false,
      selectedContact: null,
      contactDialog: false,
      selectedEmployee: null,
      employeeDialog: false,
      actDialog: false,
      startDateMenu: false,
      invoiceDateMenu: false,
      endDateMenu: false,
      includeVatCharge: false,
      customerOrderNoRequired: false,
      deliveryItems: [
        {
          selectedProduct: null,
          unit_price: null,
          item_description: null,
          total: null,
          linediscount_total: null,
        },
      ],
      customer_id: null,
      customerPage: 1,
      customerLimit: 10,
      contactSearch: "",
      contactPage: 1,
      contactLimit: 10,
      employeePage: 1,
      employeeLimit: 10,
      dialog: false,
      productDialog: false,
      productDialogIndex: null,
      selectedStatus: 0,
      orderDialog: false,
      selectedOrder: null,
      selectedProformaInvoice: null,
      selectedProduct: [],
      allSelectedProduct: false,
      showCheckboxes: false,
      deliveryChanged: false,
      copyFormLoading: true,
      relatedInvoiceItems: [],
      relatedProformaInvoicesOrderItems: [],
      relatedQuotationsItems: [],
      nextId: null,
      prevId: null,
      remarksDialog: false,
      confirmationDialogStatus: false,
      delete_item: null,
      groupValue: [],
      selectedGroup: null,
      attachedDocumentsDialog: false,
      saveOnlyCategoryStatus: false,
      itemDiscount: null,
      confirmDocumentNumberDialog: false,
      moreDocumentNumber: false,
      documentData: [],
      periodClosureConfirmationDialog: false,
      isProgressCircular: false,
      selectedWarehouse: null,
      copyAndCreateDialog: false,
      suppliedDialogBox: false,
      remainingSuppliedDialogBox: false,
    };
  },
  computed: {
    computedDateFormatted() {
      return this.profileById.date_format
        ? this.deliveryDateFormat
        : this.formatDateDDMMYYYY(this.deliveryDateFormat);
    },
    statusList() {
      if (this.model.status == 2) {
        return [
          { name: this.$t("delivery.field.open"), id: 0 },
          { name: this.$t("delivery.field.finished"), id: 1 },
          { name: this.$t("delivery.field.canceled"), id: 2 },
        ];
      } else {
        return [
          { name: this.$t("delivery.field.open"), id: 0 },
          { name: this.$t("delivery.field.finished"), id: 1 },
        ];
      }
    },
    orderValid() {
      return [
        (v) => {
          if (this.$route.params.delivery_id) {
            if (v) {
              return !!v || this.$t("formRules.orderRequired");
            } else {
              return (
                !(!!v && this.deliveryItems[0].selectedProduct === null) ||
                this.$t("formRules.orderRequired")
              );
            }
          } else {
            if (!v) {
              if (this.deliveryItems[0].selectedProduct === null) {
                return this.$t("formRules.orderRequired");
              } else return true;
            } else return true;
          }
        },
      ];
    },
    ordernoValid() {
      return [
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.ordernoInteger"),
        (v) =>
          !v ||
          (v && String(v).length <= 7) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("quotation.field.orderNo"),
            type: this.$t("common.less"),
            size: 7,
          }),
      ];
    },
    invoicenoValid() {
      return [
        (v) =>
          !v || /^[0-9]*$/.test(v) || this.$t("formRules.invoicenoInteger"),
        (v) =>
          !v ||
          (v && String(v).length <= 7) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("quotation.field.invoiceNo"),
            type: this.$t("common.less"),
            size: 7,
          }),
      ];
    },
    discountValid() {
      return [
        (v) =>
          !v ||
          /^\d{0,8}(\.\d{1,4})?$/.test(v) ||
          this.$t("formRules.spaceValidValue"),
        (v) =>
          !v ||
          (v && String(v).length <= 12) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("quotation.field.discount"),
            type: this.$t("common.less"),
            size: 12,
          }),
      ];
    },
    deliveryId() {
      return this.$route.params.delivery_id;
    },
    isDeliveryId() {
      return this.deliveryId ? true : false;
    },
    copiedDelivery() {
      return this.$route.params.copiedModel;
    },
    ...mapGetters({
      deliveryById: "delivery/deliveryById",
      locale: "locale",
      profileById: "profile/profileById",
      employeeById: "employee/employeeById",
      customerList: "customer/customerList",
      contactList: "contact/contactList",
      employeeList: "employee/employeeList",
      remarksList: "remarks/remarksList",
      deliveryQuery: "delivery/deliveryQuery",
      orderById: "order/orderById",
      proformaById: "proforma/proformaById",
      accessRight: "accessRight",
      user: "user",
      moreThanOneUserLogout: "moreThanOneUserLogout",
      periodClosureConfirmation: "multiple/periodClosureConfirmation",
      invoiceById: "invoice/invoiceById",
      receiptTaxInvoiceById: "receiptTaxInvoice/receiptTaxInvoiceById",
      creditInvoiceById: "creditInvoice/creditInvoiceById",
      returnCertificateById: "returnCertificate/returnCertificateById",
      quotationById: "quotation/quotationById",
    }),
    isFieldReadable() {
      return this.model.status == 0 ? false : true;
    },
    minDate() {
      if (this.deliveryId) {
        const date = parseDateYYYYMMDD(this.model.delivery_date)?.split("-")[0];
        return `${date}-01-01`;
      } else {
        return null;
      }
    },
    maxDate() {
      if (this.deliveryId) {
        const date = parseDateYYYYMMDD(this.model.delivery_date)?.split("-")[0];
        return `${date}-12-31`;
      } else {
        return null;
      }
    },
  },
  watch: {
    deliveryDateFormat() {
      const year = this.model.year;
      this.model.delivery_date = this.profileById.date_format
        ? this.deliveryDateFormat
        : this.formatDateDDMMYYYY(this.deliveryDateFormat);
      this.model.year = parseDateYYYYMMDD(this.deliveryDateFormat).split(
        "-"
      )[0];
      if (year && this.model.year !== year) {
        this.documentSeriesFunction();
      }
    },
    periodClosureConfirmation: {
      handler: function (val) {
        if (val) {
          this.periodClosureConfirmationDialog = true;
        }
      },
      deep: true,
    },
    customerLimit(newVal, oldVal) {
      if (newVal !== oldVal)
        this.$store.dispatch("customer/GetCustomer", {
          page: 1,
          limit: this.customerLimit,
          order_by: "name_1|asc",
        });
    },
    contactSearch(newVal, oldVal) {
      if (newVal !== oldVal)
        if (this.model.customer_id) {
          this.$store.dispatch("contact/GetContact", {
            page: 1,
            limit: 10,
            where_like: newVal ? "id,name|" + newVal : newVal,
            where_and:
              "record_type_number,record_type|" +
              this.model.customer_id +
              ",customers",
            order_by: "name|asc",
          });
        }
    },
    contactLimit(newVal, oldVal) {
      if (newVal !== oldVal)
        if (this.model.customer_id) {
          this.$store.dispatch("contact/GetContact", {
            page: 1,
            limit: this.contactLimit,
            where_and:
              "record_type_number,record_type|" +
              this.model.customer_id +
              ",customers",
            order_by: "name|asc",
          });
        }
    },
    employeeLimit(newVal, oldVal) {
      if (newVal !== oldVal)
        this.$store.dispatch("employee/GetEmployee", {
          page: 1,
          limit: this.employeeLimit,
          order_by: "name|asc",
        });
    },
    profileById() {
      if (
        Object.keys(this.profileById).length > 0 &&
        !this.$route.params.delivery_id &&
        !this.model.delivery_note_document_number &&
        !this.$route.query.orderId &&
        !this.$route.query.proformaId
      ) {
        this.model.vat_percentage = this.profileById.vat_percentage;
      }
    },
    deliveryById() {
      if (Object.keys(this.deliveryById).length > 0) {
        this.model = Object.assign({}, this.deliveryById);
        if (this.model.delivery_date) {
          this.deliveryDateFormat = new Date(this.model.delivery_date)
            .toISOString()
            .substring(0, 10);
          this.model.delivery_date = this.profileById.date_format
            ? new Date(this.model.delivery_date).toISOString().substring(0, 10)
            : this.formatDateDDMMYYYY(this.model.delivery_date);
        }
        this.selectedStatus = this.model.status;
        if (this.$route.params.type !== "Delivery") {
          this.relatedInvoiceItems = this.model.relatedInvoices.filter(
            (item) => item.related_model_id
          );
          // Related orders if DN from proforma invoice
          this.relatedProformaInvoicesOrderItems =
            this.model.relatedProformaInvoicesOrder.filter(
              (item) => item.related_model_id
            );
          // Related quotation if DN from proforma invoice
          this.relatedQuotationsItems = this.model.relatedQuotations.filter(
            (item) => item.related_model_id
          );
        }
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    invoiceById() {
      if (Object.keys(this.invoiceById).length > 0) {
        this.model = Object.assign({}, this.model, {
          ...this.invoiceById,
          creditInvoiceItems: this.invoiceById.invoiceItems,
        });
        this.model.deliveryNoteItems = [];
        delete this.model.invoiceItems;
        delete this.model.invoice_date;
        delete this.model.id;
        delete this.model.invoice_year;
        delete this.model.balance;
        delete this.model.payment_date;
        delete this.model.accounting_update;
        delete this.model.delivery_note_id;
        delete this.model.is_multiple;
        delete this.model.invoice_document_number;
        delete this.model.grand_total_requestd;
        delete this.model.deliveryNote;
        delete this.model.invoiceStatus;
        delete this.model.relatedReceipts;
        delete this.model.relatedDeliveryNote;
        delete this.model.relatedProformaInvoice;
        delete this.model.relatedCreditInvoices;
        delete this.model.relatedQuotation;
        delete this.model.relatedOrder;
        delete this.model.creditInvoiceItems;
        this.paidTotal = this.invoiceById.grand_total;
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    receiptTaxInvoiceById() {
      if (Object.keys(this.receiptTaxInvoiceById).length > 0) {
        this.model = Object.assign({}, this.model, {
          ...this.receiptTaxInvoiceById,
          creditInvoiceItems: this.receiptTaxInvoiceById.receiptTaxInvoiceItems,
        });
        delete this.model.id;
        delete this.model.receiptTaxInvoiceItems;
        delete this.model.receipt_tax_invoice_year;
        delete this.model.receipt_tax_invoice_date;
        delete this.model.balance;
        delete this.model.creditInvoiceItems;
        delete this.model.receipt_tax_invoice_payment_date;
        delete this.model.accounting_update;
        delete this.model.delivery_company;
        delete this.model.receipt_tax_invoice_delivery_date;
        delete this.model.with_holding_tax;
        delete this.model.recipt_tax_invoice_document_number;
        delete this.model.document_number_series;
        delete this.model.grand_total_requestd;
        delete this.model.warehouse;
        delete this.model.receiptTaxInvoiceStatus;
        delete this.model.receiptTaxInvoicePayments;
        delete this.model.relatedQuotation;
        delete this.model.relatedOrder;
        this.paidTotal = this.receiptTaxInvoiceById.grand_total;
        this.model.title = this.receiptTaxInvoiceById.title;
        this.selectedCustomer = {
          id: this.model?.customer_id,
          name_1: this.model.customer?.name_1,
        };
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    creditInvoiceById() {
      if (Object.keys(this.creditInvoiceById).length > 0) {
        this.model = Object.assign({}, this.model, {
          ...this.creditInvoiceById,
          creditInvoiceItems: this.creditInvoiceById.creditInvoiceItems,
        });
        delete this.model.id;
        delete this.model.creditInvoiceItems;
        delete this.model.credit_invoice_year;
        delete this.model.credit_invoice_date;
        delete this.model.balance;
        delete this.model.grand_total_requestd;
        delete this.model.creditInvoiceStatus;
        delete this.model.accounting_update;
        delete this.model.delivery_company;
        delete this.model.creditInvoicePayments;
        delete this.model.relatedReturnCertificate;
        delete this.model.warehouse;
        delete this.model.credit_invoice_document_number;
        delete this.model.document_number_series;
        this.paidTotal = this.creditInvoiceById.grand_total;
        this.model.title = this.creditInvoiceById.title;
        this.selectedCustomer = {
          id: this.model?.customer_id,
          name_1: this.model.customer?.name_1,
        };
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    returnCertificateById() {
      if (Object.keys(this.returnCertificateById).length > 0) {
        this.model = Object.assign({}, this.model, {
          ...this.returnCertificateById,
          creditInvoiceItems: this.returnCertificateById.ReturnCertificateItems,
        });
        delete this.model.id;
        delete this.model.warehouse;
        delete this.model.year;
        delete this.model.return_certificate_date;
        delete this.model.return_certificate_time;
        delete this.model.return_certificate_company;
        delete this.model.return_certificate_no;
        delete this.model.relatedDeliveryNote;
        delete this.model.return_certificate_document_number;
        delete this.model.document_number_series;
        delete this.model.balance;
        delete this.model.ReturnCertificateItems;
        delete this.model.returnCertificateStatus;
        delete this.model.grand_total_requestd;
        delete this.model.creditInvoiceItems;
        this.paidTotal = this.returnCertificateById.grand_total;
        this.model.title =
          this.returnCertificateById.return_certificate_company;
        this.selectedCustomer = {
          id: this.model?.customer_id,
          name_1: this.model.customer?.name_1,
        };
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    async moreThanOneUserLogout() {
      if (this.moreThanOneUserLogout && this.model.id) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "DeliveryNote",
          id: this.model.id,
          is_locked: false,
        });
        this.$store.dispatch("LogOut");
        this.$router.push({ name: "login" });
      }
    },
  },
  async created() {
    window.addEventListener("beforeunload", this.handler);

    if (this.$route.params.delivery_id) {
      await this.$store.dispatch(
        "delivery/GetDeliveryById",
        this.$route.params.delivery_id
      );
      if (!this.model.is_locked && this.$route.params.delivery_id) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "DeliveryNote",
          id: this.$route.params.delivery_id,
          is_locked: true,
        });
      }
    }
    if (!this.deliveryId) {
      const self = this;
      setInterval(self.getNow, 1000);
    }
  },
  async mounted() {
    this.onMounteUpdated();
    if (this.deliveryId) {
      this.$store.dispatch("remarks/GetRemarks", {
        where: "document_type|" + 30,
        where_and: "document_id|" + this.deliveryId,
      });
    }
    if (
      !this.deliveryId &&
      !this.$route.query.proformaId &&
      !this.$route.query.orderId &&
      !this.$route.params.copiedModel
    ) {
      await this.documentSeriesFunction();
    } else {
      this.documentData = [];
      await this.$store
        .dispatch(
          "documentSeries/GetDocumentSeries",
          `?where_and=type,year|Delivery,${new Date().getFullYear()}`
        )
        .then((res) => {
          if (res.length > 1) {
            this.moreDocumentNumber = true;
            this.documentData = res;
          } else {
            this.moreDocumentNumber = false;
          }
        });
      await this.$store
        .dispatch(
          "documentSeries/GetDocumentSeries",
          `?where_and=type,year|Delivery,${new Date().getFullYear()}`
        )
        .then((res) => {
          if (res.length > 0) {
            this.confirmDocumentNumberDialog = false;
          } else {
            if (this.deliveryId) {
              this.confirmDocumentNumberDialog = false;
            } else {
              this.confirmDocumentNumberDialog = true;
            }
          }
        });
    }
    this.selectedEmployee = this.user.name;
  },
  beforeDestroy() {
    if (
      (this.model.id &&
        !this.moreThanOneUserLogout &&
        this.model.is_locked &&
        this.model.user_id == this.user.id) ||
      (this.model.id && !this.model.user_id && !this.moreThanOneUserLogout)
    ) {
      this.$store.dispatch("PostDocumentOpenAction", {
        table: "DeliveryNote",
        id: this.model.id,
        is_locked: false,
      });
    }
    this.$refs.form ? this.$refs.form.reset() : "";
    this.model = {};
    this.customer_id = null;
    this.dialog = false;
    this.orderDialog = false;
    this.$store.commit("customer/SET_CUSTOMER_BY_ID", {});
    this.$store.commit("contact/SET_CONTACT", []);
    this.$store.commit("delivery/SET_DELIVERY_BY_ID", {});
    this.$store.commit("customer/SET_CUSTOMER", []);
    this.$store.commit("SET_USER_LOGOUT", false);
    window.removeEventListener("beforeunload", this.handler);
  },
  methods: {
    parseDateYYYYMMDD: parseDateYYYYMMDD,
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    todayDate: todayDate,
    async getNow() {
      const currentTime = new Date();
      const hours = currentTime.getHours().toString().padStart(2, "0");
      const minutes = currentTime.getMinutes().toString().padStart(2, "0");
      this.model.delivery_time = `${hours}:${minutes}`;
    },
    async handler(event) {
      if (
        (this.model.id &&
          !this.moreThanOneUserLogout &&
          this.model.is_locked &&
          this.model.user_id == this.user.id) ||
        (this.model.id && !this.model.user_id && !this.moreThanOneUserLogout)
      ) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "DeliveryNote",
          id: this.model.id,
          is_locked: false,
        });
      }
      event.preventDefault();
    },
    async documentSeriesFunction() {
      this.documentData = [];
      await this.$store
        .dispatch(
          "documentSeries/GetDocumentSeries",
          `?where_and=type,year|Delivery,${this.model.year}`
        )
        .then((res) => {
          if (res.length > 1) {
            this.moreDocumentNumber = true;
            this.documentData = res;
          } else {
            this.moreDocumentNumber = false;
          }
        });
      await this.$store
        .dispatch(
          "documentSeries/GetDocumentSeries",
          `?where_and=type,year|Delivery,${this.model.year}`
        )
        .then((res) => {
          if (res.length > 0) {
            this.confirmDocumentNumberDialog = false;
          } else {
            if (this.deliveryId) {
              this.confirmDocumentNumberDialog = false;
            } else {
              this.confirmDocumentNumberDialog = true;
            }
          }
        });
    },
    async onMounteUpdated() {
      if (this.$route.params.copiedModel) {
        this.model = this.$route.params.copiedModel;
        this.copyFormLoading = false;
        if (this.model.employee_id) {
          this.selectedEmployee = {
            id: this.model?.employee?.id,
            name: this.model?.employee?.name,
          };
          this.model.employee_id = this.model?.employee_id;
        }
        this.customer_id = this.model.customer_id;
        this.selectedCustomer = {
          id: this.model?.customer_id,
          name_1: this.model.customer?.name_1,
        };
        this.model.customer_id = this.model.customer.id;
        if (this.model.contact) {
          this.selectedContact = {
            id: this.model.contact?.id,
            name: this.model.contact?.name,
          };
        }
        this.includeVatCharge = this.model.customer.vat_charge;
        this.customerOrderNoRequired =
          this.model.customer.customer_order_no_compulsory;
        if (this.$route.params.type !== "Delivery") {
          this.selectedOrder = this.model.order?.id;
        }
        if (this.model.proforma_invoice_id) {
          if (this.$route.params.type !== "Delivery") {
            this.selectedProformaInvoice = this.model.proforma_invoice_id;
          }
        }
        this.selectedGroup = null;
        this.selectedWarehouse = null;
        this.model.status_one = null;
        this.model.warehouse = null;
        await this.$store
          .dispatch(
            "delivery/GetDeliveryById",
            this.$route.params.copiedModel.id
          )
          .then((res) => {
            if (res) {
              this.model.customer_name = res.customer?.name_1;
              this.model.address = res.customer?.address_1;
              this.model.town = res.customer?.town_1;
              this.model.vat_percentage = res.vat_percentage;
              this.model.delivery_company = res.delivery_company;
              this.model.customer_id = res.customer?.id;
              this.model.remarks = res.remarks;
              this.selectedGroup = res.status_one;
            }
          });
        this.model.status = 0;
        this.selectedStatus = this.model.status;
        if (this.model.order_id) {
          this.$store.dispatch("order/GetOrderById", this.model.order_id);
        }
        if (this.model.deliveryNoteItems.length < 1) {
          this.deliveryItems = [
            {
              selectedProduct: null,
              unit_price: null,
              item_description: null,
              total: null,
            },
          ];
        } else {
          this.deliveryItems = [];
        }
        this.selectedProduct = [];
        this.allSelectedProduct = false;
        this.model.total = 0.0;
        if (this.model.order_id || this.model.proforma_invoice_id) {
          this.model.deliveryNoteItems.forEach((element) => {
            let orderItemId = element["order_item_id"];
            delete element["created_at"];
            delete element["updated_at"];
            delete element["order_item_id"];
            delete element["delivery_note_id"];
            delete element["id"];
            delete element["inventory"];
            delete element["supplied"];
            delete element["remainingQuantity"];
            delete element["year"];
            if (element["unit_price"] && !element["quantity"]) {
              element = Object.assign({}, { ...element, quantity: 1 });
            }
            if (this.model.proforma_invoice_id) {
              this.deliveryItems.push(
                Object.assign({}, element, {
                  proforma_invoice_item_id: orderItemId,
                  selectedProduct: {
                    item_id: element.item_id,
                    item_code: element.item_code,
                  },
                })
              );
              this.selectedProduct.push(
                Object.assign({}, element, {
                  proforma_invoice_item_id: orderItemId,
                  selectedProduct: {
                    item_id: element.item_id,
                    item_code: element.item_code,
                  },
                })
              );
            } else {
              this.deliveryItems.push(
                Object.assign({}, element, {
                  order_item_id: orderItemId,
                  selectedProduct: {
                    item_id: element.item_id,
                    item_code: element.item_code,
                  },
                })
              );
              this.selectedProduct.push(
                Object.assign({}, element, {
                  order_item_id: orderItemId,
                  selectedProduct: {
                    item_id: element.item_id,
                    item_code: element.item_code,
                  },
                })
              );
            }
            this.model.total += parseFloat(element.total);
          });
        } else {
          this.model.deliveryNoteItems.forEach((element) => {
            delete element["created_at"];
            delete element["updated_at"];
            delete element["order_item_id"];
            delete element["delivery_note_id"];
            delete element["remainingQuantity"];
            delete element["calculated_discount"];
            delete element["multiple_doc_type"];
            delete element["id"];
            delete element["inventory"];
            delete element["supplied"];
            delete element["year"];
            let unit_price_change = this.numberWithCommas(
              element["unit_price"],
              "noFloat"
            );
            let total_change = this.numberWithCommas(
              element["total"],
              "noFloat"
            );
            if (element["unit_price"] && !element["quantity"]) {
              element = Object.assign({}, { ...element, quantity: 1 });
            }
            this.deliveryItems.push(
              Object.assign(
                {},
                {
                  ...element,
                  total: total_change,
                  unit_price: unit_price_change,
                },
                {
                  selectedProduct: {
                    item_id: element.item_id,
                    item_code: element.item_code,
                  },
                }
              )
            );
            this.model.total += parseFloat(element.total);
          });
        }
        if (this.model.order_id) {
          if (
            this.model.deliveryNoteItems.length == this.selectedProduct.length
          ) {
            this.allSelectedProduct = true;
          }
          this.model.discount_percentage = this.orderById.discount_percentage;
          this.model.vat_percentage = this.orderById.vat_percentage;
        }

        if (this.model.proforma_invoice_id) {
          if (this.deliveryItems?.length == this.selectedProduct.length) {
            this.allSelectedProduct = true;
          }
        }
        this.model.year = new Date().getFullYear();
        this.model.delivery_date = this.profileById.date_format
          ? new Date().toISOString().substring(0, 10)
          : this.formatDateDDMMYYYY(new Date().toISOString().substring(0, 10));
        if (this.model.status === 0) {
          this.$nextTick(() => {
            this.$refs.form.validate();
          });
        }
        this.model.status = 0;
        this.deliveryPriceCalculation();
      } else if (this.$route.params.delivery_id) {
        this.documentNextAndPrevious();
        await this.$store
          .dispatch("delivery/GetDeliveryById", this.$route.params.delivery_id)
          .then((res) => {
            if (res.delivery_note_document_number) {
              document.title =
                document.title + "   " + res.delivery_note_document_number;
            }
          });
        this.model.customer_id
          ? this.$store.dispatch(
              "customer/GetCustomerById",
              this.model.customer_id
            )
          : "";
        this.customer_id = this.model?.customer_id;
        this.selectedCustomer = {
          id: this.model?.customer_id,
          name_1: this.model.customer?.name_1,
          fixed_discount: this.model.customer?.fixed_discount,
        };
        this.model.customer_id = this.model?.customer?.id;
        if (this.model.contact) {
          this.selectedContact = {
            id: this.model.contact?.id,
            name: this.model.contact?.name,
          };
        }
        if (this.model.employee_id) {
          this.selectedEmployee = {
            id: this.model?.employee?.id,
            name: this.model?.employee?.name,
          };
          this.model.employee_id = this.model?.employee_id;
        }
        this.includeVatCharge = this.model?.customer?.vat_charge;
        this.customerOrderNoRequired =
          this.model?.customer?.customer_order_no_compulsory;
        if (this.$route.query.type !== "copy-create") {
          this.selectedOrder = this.model.order?.id;
        }
        if (this.$route.query.type !== "copy-create") {
          this.selectedProformaInvoice = this.model.proforma_invoice_id;
        }
        if (this.model.proforma_invoice_id) {
          this.$store.dispatch(
            "proforma/GetProformaById",
            this.model.proforma_invoice_id
          );
        }
        this.selectedGroup = this.model.status_one;
        this.selectedWarehouse = this.model.warehouse;
        this.selectedStatus = this.model.status;
        if (this.model.order_id) {
          this.$store.dispatch("order/GetOrderById", this.model.order_id);
        }
        this.deliveryItems = [];
        this.selectedProduct = [];
        if (this.model.order_id) {
          this.model.deliveryNoteItems?.forEach((element) => {
            delete element["created_at"];
            delete element["updated_at"];
            if (element["unit_price"] && !element["quantity"]) {
              element = Object.assign({}, { ...element, quantity: 1 });
            }
            this.deliveryItems.push(
              Object.assign({}, element, {
                selectedProduct: {
                  item_id: element.item_id,
                  item_code: element.item_code,
                },
              })
            );
            this.selectedProduct.push(
              Object.assign({}, element, {
                selectedProduct: {
                  item_id: element.item_id,
                  item_code: element.item_code,
                },
              })
            );
          });
        } else {
          this.model.deliveryNoteItems?.forEach((element) => {
            delete element["created_at"];
            delete element["updated_at"];
            let unit_price_change = this.numberWithCommas(
              element["unit_price"],
              "noFloat"
            );

            if (element.doc_discount) {
              let self = this;
              unit_price_change =
                this.deliveryItems.length > 0
                  ? this.deliveryItems
                      .reduce(function (total, item) {
                        console.log(
                          " parseFloat(self.numberFromCommas(item.total)): ",
                          parseFloat(self.numberFromCommas(item.total))
                        );
                        return parseFloat(
                          self.numberFromCommas(item.calculated_discount)
                        )
                          ? parseFloat(item.total) +
                              parseFloat(
                                self.numberFromCommas(item.calculated_discount)
                              )
                          : total + 0;
                      }, 0)
                      .toFixed(2)
                  : 0;
            } else {
              unit_price_change = this.numberWithCommas(
                element["unit_price"],
                "noFloat"
              );
            }
            let self = this;
            let linediscount_total =
              this.deliveryItems.length > 0
                ? this.deliveryItems
                    .reduce(function (total, item) {
                      console.log(
                        " parseFloat(self.numberFromCommas(item.total)): ",
                        parseFloat(self.numberFromCommas(item.total))
                      );
                      return parseFloat(
                        self.numberFromCommas(item.calculated_discount)
                      )
                        ? parseFloat(item.total) +
                            parseFloat(
                              self.numberFromCommas(item.calculated_discount)
                            )
                        : total + 0;
                    }, 0)
                    .toFixed(2)
                : 0;

            let total_change = this.numberWithCommas(
              element["total"],
              "noFloat"
            );

            if (element["unit_price"] && !element["quantity"]) {
              element = Object.assign({}, { ...element, quantity: 1 });
            }
            this.deliveryItems.push(
              Object.assign(
                {},
                {
                  ...element,
                  linediscount_total: linediscount_total,
                  total: total_change,
                  unit_price: unit_price_change,
                },
                {
                  selectedProduct: {
                    item_id: element.item_id,
                    item_code: element.item_code,
                  },
                }
              )
            );
            if (this.model.proforma_invoice_id) {
              this.selectedProduct.push(
                Object.assign(
                  {},
                  {
                    ...element,
                    total: total_change,
                    unit_price: unit_price_change,
                  },
                  {
                    selectedProduct: {
                      item_id: element.item_id,
                      item_code: element.item_code,
                    },
                  }
                )
              );
            }
          });
        }

        if (this.model.order_id) {
          if (
            this.orderById?.orderItems?.length == this.selectedProduct.length
          ) {
            this.allSelectedProduct = true;
          }
        }
        if (this.model.proforma_invoice_id) {
          if (this.deliveryItems?.length == this.selectedProduct.length) {
            this.allSelectedProduct = true;
          }
        }
        if (this.model.status === 0) {
          this.$refs.form.validate();
          this.deliveryPriceCalculation();
        }
      } else {
        if (this.$route.query.orderId) {
          if (this.$route.query.type !== "copy-create") {
            this.selectedOrder = this.$route.query.orderId;
          }
          let customer = await this.$store.dispatch(
            "customer/GetCustomerById",
            this.$route.query.customerId
          );
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
          this.model.customer = customer;
          this.customer_id = this.$route.query.customerId;
          this.selectedCustomer = {
            id: this.model.customer?.id,
            name_1: this.model.customer?.name_1,
          };
          this.model.customer_id = this.model.customer.id;
          this.includeVatCharge = this.model.customer.vat_charge;
          this.customerOrderNoRequired =
            this.model.customer.customer_order_no_compulsory;
          let order = await this.$store.dispatch(
            "order/GetOrderById",
            this.$route.query.orderId
          );
          this.model.customer_order_no = order.customer_order_no;
          this.model.authorized_dealer_no = order.authorized_dealer_no;
          this.model.email = order.email;
          this.model.address = order.address;
          this.model.town = order.town;
          this.model.country = order.country;
          this.model.phone_1 = order.phone_1;
          this.model.phone_2 = order.phone_2;
          this.model.cellular = order.cellular;
          this.model.fax = order.fax;
          this.model.customer_name = order.customer_name;
          if (order.employee_id) {
            this.selectedEmployee = {
              id: order.employee?.id,
              name: order.employee?.name,
            };
            this.model.employee_id = order.employee_id;
          }
          if (order.contact_id) {
            this.selectedContact = {
              id: order.contact?.id,
              name: order.contact?.name,
            };
            this.model.contact_id = order.contact_id;
          }
          this.model.order = order;
          this.model.remarks = order.remarks;
          this.model.delivery_company = this.model.order.title;
          if (this.$route.query.type !== "copy-create") {
            this.model.order_id = this.$route.query.orderId;
          }
          this.deliveryItems = [];
          this.allSelectedProduct = false;
          this.selectedProduct = [];
          this.model.status = 0;
          this.selectedStatus = this.model.status;
          // add order item in delivery if not selected
          let orderItemsData = order.orderItems;
          orderItemsData.forEach((element) => {
            let orderItemId = element.id;
            if (this.$route.query.type === "copy-create") {
              delete element["order_item_id"];
              delete element["remainingQuantity"];
              delete element["multiple_doc_type"];
            }
            delete element["calculated_discount"];
            delete element["created_at"];
            delete element["updated_at"];
            delete element["inventory"];
            delete element["document_number"];
            delete element["document_date"];
            delete element["customer_order_no"];
            delete element["title"];
            delete element["quotation_item_id"];
            delete element["order_id"];
            delete element["remainder"];
            delete element["supplied"];
            delete element["id"];
            if (element.item_code) {
              if (element["unit_price"] && !element["quantity"]) {
                element = Object.assign({}, { ...element, quantity: 1 });
              }
              if (this.$route.query.type === "copy-create") {
                this.deliveryItems.push(
                  Object.assign({}, element, {
                    selectedProduct: {
                      item_id: element.item_id,
                      item_code: element.item_code,
                    },
                  })
                );
                this.selectedProduct.push(
                  Object.assign({}, element, {
                    selectedProduct: {
                      item_id: element.item_id,
                      item_code: element.item_code,
                    },
                  })
                );
              } else {
                let unit_price_change = this.numberWithCommas(
                  element["unit_price"],
                  "noFloat"
                );
                let total_change_2 =
                  element.remainingQuantity * element.unit_price;
                let total_change_3 =
                  (total_change_2 * element.line_discount) / 100;
                let total_change_4 = total_change_2 - total_change_3;
                let total_change_price = this.numberWithCommas(total_change_4);
                let quantity_change = element["remainingQuantity"]
                  ? element["remainingQuantity"]
                  : element["quantity"];

                if (element.remainingQuantity) {
                  this.deliveryItems.push(
                    Object.assign({}, element, {
                      quantity: quantity_change,
                      total: total_change_price,
                      unit_price: unit_price_change,
                      order_item_id: orderItemId,
                      selectedProduct: {
                        item_id: element.item_id,
                        item_code: element.item_code,
                      },
                      type: "saveCreate",
                    })
                  );
                  this.selectedProduct.push(
                    Object.assign({}, element, {
                      quantity: quantity_change,
                      total: total_change_price,
                      unit_price: unit_price_change,
                      order_item_id: orderItemId,
                      selectedProduct: {
                        item_id: element.item_id,
                        item_code: element.item_code,
                      },
                      type: "saveCreate",
                    })
                  );
                } else {
                  this.deliveryItems.push(
                    Object.assign({}, element, {
                      selectedProduct: {
                        item_id: element.item_id,
                        item_code: element.item_code,
                      },
                      type: "saveCreate",
                    })
                  );
                  this.selectedProduct.push(
                    Object.assign({}, element, {
                      selectedProduct: {
                        item_id: element.item_id,
                        item_code: element.item_code,
                      },
                      type: "saveCreate",
                    })
                  );
                }
              }
              this.model.total += parseFloat(element.total);
            }
          });

          if (order.orderItems.length == this.selectedProduct.length) {
            this.allSelectedProduct = true;
          }
          delete this.model.order;
          this.model.discount_percentage = order.discount_percentage;
          this.model.vat_percentage = order.vat_percentage;
          this.deliveryPriceCalculation();
        } else if (this.$route.query.proformaId) {
          if (this.$route.query.type !== "copy-create") {
            this.selectedProformaInvoice = this.$route.query.proformaId;
          }
          let customer = await this.$store.dispatch(
            "customer/GetCustomerById",
            this.$route.query.customerId
          );
          this.model.customer = customer;
          this.customer_id = this.$route.query.customerId;
          this.selectedCustomer = {
            id: this.model.customer?.id,
            name_1: this.model.customer?.name_1,
          };
          this.model.customer_id = this.model.customer.id;
          this.includeVatCharge = this.model.customer.vat_charge;
          this.customerOrderNoRequired =
            this.model.customer.customer_order_no_compulsory;
          let proforma = await this.$store.dispatch(
            "proforma/GetProformaById",
            this.$route.query.proformaId
          );
          this.model.customer_order_no = proforma.customer_order_no;
          this.model.address = proforma.address;
          this.model.town = proforma.town;
          this.model.authorized_dealer_no = proforma.authorized_dealer_no;
          this.model.email = proforma.email;
          this.model.country = proforma.country;
          this.model.phone_1 = proforma.phone_1;
          this.model.phone_2 = proforma.phone_2;
          this.model.cellular = proforma.cellular;
          this.model.fax = proforma.fax;
          this.model.customer_name = proforma.customer_name;
          if (proforma.employee_id) {
            this.selectedEmployee = {
              id: proforma.employee?.id,
              name: proforma.employee?.name,
            };
            this.model.employee_id = proforma.employee_id;
          }
          if (proforma.contact_id) {
            this.selectedContact = {
              id: proforma.contact?.id,
              name: proforma.contact?.name,
            };
            this.model.contact_id = proforma.contact_id;
          }
          this.model.remarks = proforma.remarks;
          this.model.delivery_company = proforma.title;
          if (this.$route.query.type !== "copy-create") {
            this.model.proforma_invoice_id = this.$route.query.proformaId;
          }
          if (this.model.deliveryItems && this.model.deliveryItems.length < 1) {
            this.deliveryItems = [
              {
                selectedProduct: null,
                unit_price: null,
                item_description: null,
                total: null,
              },
            ];
          } else {
            if (
              this.model.deliveryNoteItems &&
              this.model.deliveryNoteItems.length < 1
            ) {
              this.deliveryItems = [
                {
                  selectedProduct: null,
                  unit_price: null,
                  item_description: null,
                  total: null,
                },
              ];
            } else {
              this.deliveryItems = [];
            }
          }
          this.allSelectedProduct = false;
          this.selectedProduct = [];
          this.model.status = 0;
          this.selectedStatus = this.model.status;
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
          // add order item in delivery if not selected
          let orderItemsData = proforma.proformaInvoiceItems;
          orderItemsData.forEach((element) => {
            let orderItemId = element.id;
            delete element["created_at"];
            delete element["updated_at"];
            delete element["quotation_item_id"];
            if (this.$route.query.type === "copy-create") {
              delete element["remainingQuantity"];
              delete element["multiple_doc_type"];
            }
            delete element["document_number"];
            delete element["calculated_discount"];
            delete element["proforma_invoice_id"];
            delete element["proforma_invoice_item_id"];
            delete element["inventory"];
            delete element["proforma_invoice_year"];
            delete element["order_item_id"];
            delete element["remainder"];
            delete element["supplied"];
            delete element["id"];
            if (element.item_code) {
              if (element["unit_price"] && !element["quantity"]) {
                element = Object.assign({}, { ...element, quantity: 1 });
              }
              if (this.$route.query.type === "copy-create") {
                this.deliveryItems.push(
                  Object.assign({}, element, {
                    selectedProduct: {
                      item_id: element.item_id,
                      item_code: element.item_code,
                    },
                  })
                );
                this.selectedProduct.push(
                  Object.assign({}, element, {
                    selectedProduct: {
                      item_id: element.item_id,
                      item_code: element.item_code,
                    },
                  })
                );
              } else {
                let unit_price_change = this.numberWithCommas(
                  element["unit_price"],
                  "noFloat"
                );
                let total_change_2 =
                  element.remainingQuantity * element.unit_price;
                let total_change_3 =
                  (total_change_2 * element.line_discount) / 100;
                let total_change_4 = total_change_2 - total_change_3;
                let total_change_price = this.numberWithCommas(total_change_4);
                let quantity_change = element["remainingQuantity"]
                  ? element["remainingQuantity"]
                  : element["quantity"];

                if (element.remainingQuantity) {
                  this.deliveryItems.push(
                    Object.assign({}, element, {
                      quantity: quantity_change,
                      total: total_change_price,
                      unit_price: unit_price_change,
                      proforma_invoice_item_id: orderItemId,
                      selectedProduct: {
                        item_id: element.item_id,
                        item_code: element.item_code,
                      },
                      type: "saveCreate",
                    })
                  );
                  this.selectedProduct.push(
                    Object.assign({}, element, {
                      quantity: quantity_change,
                      total: total_change_price,
                      unit_price: unit_price_change,
                      proforma_invoice_item_id: orderItemId,
                      selectedProduct: {
                        item_id: element.item_id,
                        item_code: element.item_code,
                      },
                      type: "saveCreate",
                    })
                  );
                } else {
                  this.deliveryItems.push(
                    Object.assign({}, element, {
                      selectedProduct: {
                        item_id: element.item_id,
                        item_code: element.item_code,
                      },
                      type: "saveCreate",
                    })
                  );
                  this.selectedProduct.push(
                    Object.assign({}, element, {
                      selectedProduct: {
                        item_id: element.item_id,
                        item_code: element.item_code,
                      },
                      type: "saveCreate",
                    })
                  );
                }
              }
              this.model.total += parseFloat(element.total);
            }
          });

          if (
            proforma.proformaInvoiceItems.length == this.selectedProduct.length
          ) {
            this.allSelectedProduct = true;
          }

          this.model.discount_percentage = proforma.discount_percentage;
          this.model.vat_percentage = proforma.vat_percentage;
          this.deliveryPriceCalculation();
        } else if (this.$route.query.quotationId) {
          let customer = await this.$store.dispatch(
            "customer/GetCustomerById",
            this.$route.query.customerId
          );
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
          this.model.customer = customer;
          this.customer_id = this.$route.query.customerId;
          this.selectedCustomer = {
            id: this.model.customer?.id,
            name_1: this.model.customer?.name_1,
          };
          this.model.customer_id = this.model.customer.id;
          this.includeVatCharge = this.model.customer.vat_charge;
          this.customerOrderNoRequired =
            this.model.customer.customer_order_no_compulsory;
          let quotation = await this.$store.dispatch(
            "quotation/GetQuotationById",
            this.$route.query.quotationId
          );
          this.model.customer_order_no = quotation.customer_order_no;
          this.model.authorized_dealer_no = quotation.authorized_dealer_no;
          this.model.email = quotation.email;
          this.model.address = quotation.address;
          this.model.town = quotation.town;
          this.model.country = quotation.country;
          this.model.phone_1 = quotation.phone_1;
          this.model.phone_2 = quotation.phone_2;
          this.model.cellular = quotation.cellular;
          this.model.fax = quotation.fax;
          this.model.customer_name = quotation.customer_name;
          if (quotation.employee_id) {
            this.selectedEmployee = {
              id: quotation.employee?.id,
              name: quotation.employee?.name,
            };
            this.model.employee_id = quotation.employee_id;
          }
          if (quotation.contact_id) {
            this.selectedContact = {
              id: quotation.contact?.id,
              name: quotation.contact?.name,
            };
            this.model.contact_id = quotation.contact_id;
          }
          this.model.remarks = quotation.remarks;
          this.model.delivery_company = quotation.title;
          if (this.$route.query.type !== "copy-create") {
            this.model.proforma_invoice_id = this.$route.query.proformaId;
          }
          if (this.model.deliveryItems && this.model.deliveryItems.length < 1) {
            this.deliveryItems = [
              {
                selectedProduct: null,
                unit_price: null,
                item_description: null,
                total: null,
              },
            ];
          } else {
            if (
              this.model.deliveryNoteItems &&
              this.model.deliveryNoteItems.length < 1
            ) {
              this.deliveryItems = [
                {
                  selectedProduct: null,
                  unit_price: null,
                  item_description: null,
                  total: null,
                },
              ];
            } else {
              this.deliveryItems = [];
            }
          }
          this.allSelectedProduct = false;
          this.selectedProduct = [];
          this.model.status = 0;
          this.selectedStatus = this.model.status;
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
          let orderItemsData = quotation.quotationItems;
          orderItemsData.forEach((element) => {
            delete element["created_at"];
            delete element["updated_at"];
            delete element["quotation_item_id"];
            delete element["proforma_invoice_id"];
            delete element["proforma_invoice_item_id"];
            delete element["proforma_invoice_year"];
            delete element["order_item_id"];
            delete element["remainder"];
            delete element["inventory"];
            delete element["supplied"];
            delete element["quotation_id"];
            delete element["remainingQuantity"];
            delete element["id"];
            if (element.item_code) {
              if (element["unit_price"] && !element["quantity"]) {
                element = Object.assign({}, { ...element, quantity: 1 });
              }
              this.deliveryItems.push(
                Object.assign({}, element, {
                  selectedProduct: {
                    item_id: element.item_id,
                    item_code: element.item_code,
                  },
                })
              );
              this.selectedProduct.push(
                Object.assign({}, element, {
                  selectedProduct: {
                    item_id: element.item_id,
                    item_code: element.item_code,
                  },
                })
              );
              this.model.total += parseFloat(element.total);
            }
          });

          if (quotation.quotationItems.length == this.selectedProduct.length) {
            this.allSelectedProduct = true;
          }

          this.model.discount_percentage = quotation.discount_percentage;
          this.model.vat_percentage = quotation.vat_percentage;
          this.deliveryPriceCalculation();
        } else if (this.$route.query.invoiceId) {
          let customer = await this.$store.dispatch(
            "customer/GetCustomerById",
            this.$route.query.customerId
          );
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
          this.model.customer = customer;
          this.customer_id = this.$route.query.customerId;
          this.selectedCustomer = {
            id: this.model.customer?.id,
            name_1: this.model.customer?.name_1,
          };
          this.model.customer_id = this.model.customer.id;
          this.includeVatCharge = this.model.customer.vat_charge;
          this.customerOrderNoRequired =
            this.model.customer.customer_order_no_compulsory;
          let invoice = await this.$store.dispatch(
            "invoice/GetInvoiceById",
            this.$route.query.invoiceId
          );
          this.model.customer_order_no = invoice.customer_order_no;
          this.model.authorized_dealer_no = invoice.authorized_dealer_no;
          this.model.email = invoice.email;
          this.model.address = invoice.address;
          this.model.town = invoice.town;
          this.model.country = invoice.country;
          this.model.phone_1 = invoice.phone_1;
          this.model.phone_2 = invoice.phone_2;
          this.model.cellular = invoice.cellular;
          this.model.fax = invoice.fax;
          this.model.customer_name = invoice.customer_name;
          if (invoice.employee_id) {
            this.selectedEmployee = {
              id: invoice.employee?.id,
              name: invoice.employee?.name,
            };
            this.model.employee_id = invoice.employee_id;
          }
          if (invoice.contact_id) {
            this.selectedContact = {
              id: invoice.contact?.id,
              name: invoice.contact?.name,
            };
            this.model.contact_id = invoice.contact_id;
          }
          this.model.remarks = invoice.remarks;
          this.model.delivery_company = invoice.title;
          if (this.model.deliveryItems && this.model.deliveryItems.length < 1) {
            this.deliveryItems = [
              {
                selectedProduct: null,
                unit_price: null,
                item_description: null,
                total: null,
              },
            ];
          } else {
            if (
              this.model.deliveryNoteItems &&
              this.model.deliveryNoteItems.length < 0
            ) {
              this.deliveryItems = [
                {
                  selectedProduct: null,
                  unit_price: null,
                  item_description: null,
                  total: null,
                },
              ];
            } else {
              this.deliveryItems = [];
            }
          }
          this.allSelectedProduct = false;
          this.selectedProduct = [];
          this.model.status = 0;
          this.selectedStatus = this.model.status;
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
          let orderItemsData = invoice.invoiceItems;
          orderItemsData.forEach((element) => {
            delete element["invoice_id"];
            delete element["invoice_year"];
            delete element["created_at"];
            delete element["updated_at"];
            delete element["invoice_doc_number"];
            delete element["inventory"];
            delete element["remainingQuantity"];
            delete element["proforma_invoice_id"];
            delete element["order_id"];
            delete element["invoice_item_id"];
            delete element["delivery_note_item_id"];
            delete element["id"];
            delete element["supplied"];
            delete element["calculated_discount"];
            delete element["multiple_doc_type"];
            if (element.item_code) {
              if (element["unit_price"] && !element["quantity"]) {
                element = Object.assign({}, { ...element, quantity: 1 });
              }
              this.deliveryItems.push(
                Object.assign({}, element, {
                  selectedProduct: {
                    item_id: element.item_id,
                    item_code: element.item_code,
                  },
                })
              );
              this.selectedProduct.push(
                Object.assign({}, element, {
                  selectedProduct: {
                    item_id: element.item_id,
                    item_code: element.item_code,
                  },
                })
              );
              this.model.total += parseFloat(element.total);
            }
          });

          if (invoice.invoiceItems.length == this.selectedProduct.length) {
            this.allSelectedProduct = true;
          }

          this.model.discount_percentage = invoice.discount_percentage;
          this.model.vat_percentage = invoice.vat_percentage;
          this.deliveryPriceCalculation();
        } else if (this.$route.query.receiptTaxInvoiceId) {
          let customer = await this.$store.dispatch(
            "customer/GetCustomerById",
            this.$route.query.customerId
          );
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
          this.model.customer = customer;
          this.customer_id = this.$route.query.customerId;
          this.selectedCustomer = {
            id: this.model.customer?.id,
            name_1: this.model.customer?.name_1,
          };
          this.model.customer_id = this.model.customer.id;
          this.includeVatCharge = this.model.customer.vat_charge;
          this.customerOrderNoRequired =
            this.model.customer.customer_order_no_compulsory;
          let receiptTaxInvoice = await this.$store.dispatch(
            "receiptTaxInvoice/GetReceiptTaxInvoiceById",
            this.$route.query.receiptTaxInvoiceId
          );
          this.model.customer_order_no = receiptTaxInvoice.customer_order_no;
          this.model.authorized_dealer_no =
            receiptTaxInvoice.authorized_dealer_no;
          this.model.email = receiptTaxInvoice.email;
          this.model.address = receiptTaxInvoice.address;
          this.model.town = receiptTaxInvoice.town;
          this.model.country = receiptTaxInvoice.country;
          this.model.phone_1 = receiptTaxInvoice.phone_1;
          this.model.phone_2 = receiptTaxInvoice.phone_2;
          this.model.cellular = receiptTaxInvoice.cellular;
          this.model.fax = receiptTaxInvoice.fax;
          this.model.customer_name = receiptTaxInvoice.customer_name;
          if (receiptTaxInvoice.employee_id) {
            this.selectedEmployee = {
              id: receiptTaxInvoice.employee?.id,
              name: receiptTaxInvoice.employee?.name,
            };
            this.model.employee_id = receiptTaxInvoice.employee_id;
          }
          if (receiptTaxInvoice.contact_id) {
            this.selectedContact = {
              id: receiptTaxInvoice.contact?.id,
              name: receiptTaxInvoice.contact?.name,
            };
            this.model.contact_id = receiptTaxInvoice.contact_id;
          }
          this.model.remarks = receiptTaxInvoice.remarks;
          this.model.delivery_company = receiptTaxInvoice.title;
          if (this.model.deliveryItems && this.model.deliveryItems.length < 1) {
            this.deliveryItems = [
              {
                selectedProduct: null,
                unit_price: null,
                item_description: null,
                total: null,
              },
            ];
          } else {
            if (
              this.model.deliveryNoteItems &&
              this.model.deliveryNoteItems.length < 0
            ) {
              this.deliveryItems = [
                {
                  selectedProduct: null,
                  unit_price: null,
                  item_description: null,
                  total: null,
                },
              ];
            } else {
              this.deliveryItems = [];
            }
          }
          this.allSelectedProduct = false;
          this.selectedProduct = [];
          this.model.status = 0;
          this.selectedStatus = this.model.status;
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
          let orderItemsData = receiptTaxInvoice.receiptTaxInvoiceItems;
          orderItemsData.forEach((element) => {
            delete element["receipt_tax_invoice_id"];
            delete element["receipt_tax_invoice_item_id"];
            delete element["receipt_tax_invoice_year"];
            delete element["inventory"];
            delete element["order_id"];
            delete element["id"];
            delete element["supplied"];
            delete element["created_at"];
            delete element["updated_at"];
            delete element["calculated_discount"];
            delete element["multiple_doc_type"];
            if (element.item_code) {
              if (element["unit_price"] && !element["quantity"]) {
                element = Object.assign({}, { ...element, quantity: 1 });
              }
              this.deliveryItems.push(
                Object.assign({}, element, {
                  selectedProduct: {
                    item_id: element.item_id,
                    item_code: element.item_code,
                  },
                })
              );
              this.selectedProduct.push(
                Object.assign({}, element, {
                  selectedProduct: {
                    item_id: element.item_id,
                    item_code: element.item_code,
                  },
                })
              );
              this.model.total += parseFloat(element.total);
            }
          });

          if (
            receiptTaxInvoice.receiptTaxInvoiceItems.length ==
            this.selectedProduct.length
          ) {
            this.allSelectedProduct = true;
          }

          this.model.discount_percentage =
            receiptTaxInvoice.discount_percentage;
          this.model.vat_percentage = receiptTaxInvoice.vat_percentage;
          this.deliveryPriceCalculation();
        } else if (this.$route.query.creditInvoiceId) {
          let customer = await this.$store.dispatch(
            "customer/GetCustomerById",
            this.$route.query.customerId
          );
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
          this.model.customer = customer;
          this.customer_id = this.$route.query.customerId;
          this.selectedCustomer = {
            id: this.model.customer?.id,
            name_1: this.model.customer?.name_1,
          };
          this.model.customer_id = this.model.customer.id;
          this.includeVatCharge = this.model.customer.vat_charge;
          this.customerOrderNoRequired =
            this.model.customer.customer_order_no_compulsory;
          let creditInvoice = await this.$store.dispatch(
            "creditInvoice/GetCreditInvoiceById",
            this.$route.query.creditInvoiceId
          );
          this.model.customer_order_no = creditInvoice.customer_order_no;
          this.model.authorized_dealer_no = creditInvoice.authorized_dealer_no;
          this.model.email = creditInvoice.email;
          this.model.address = creditInvoice.address;
          this.model.town = creditInvoice.town;
          this.model.country = creditInvoice.country;
          this.model.phone_1 = creditInvoice.phone_1;
          this.model.phone_2 = creditInvoice.phone_2;
          this.model.cellular = creditInvoice.cellular;
          this.model.fax = creditInvoice.fax;
          this.model.customer_name = creditInvoice.customer_name;
          if (creditInvoice.employee_id) {
            this.selectedEmployee = {
              id: creditInvoice.employee?.id,
              name: creditInvoice.employee?.name,
            };
            this.model.employee_id = creditInvoice.employee_id;
          }
          if (creditInvoice.contact_id) {
            this.selectedContact = {
              id: creditInvoice.contact?.id,
              name: creditInvoice.contact?.name,
            };
            this.model.contact_id = creditInvoice.contact_id;
          }
          this.model.remarks = creditInvoice.remarks;
          this.model.delivery_company = creditInvoice.title;
          if (this.model.deliveryItems && this.model.deliveryItems.length < 1) {
            this.deliveryItems = [
              {
                selectedProduct: null,
                unit_price: null,
                item_description: null,
                total: null,
              },
            ];
          } else {
            if (
              this.model.deliveryNoteItems &&
              this.model.deliveryNoteItems.length < 0
            ) {
              this.deliveryItems = [
                {
                  selectedProduct: null,
                  unit_price: null,
                  item_description: null,
                  total: null,
                },
              ];
            } else {
              this.deliveryItems = [];
            }
          }
          this.allSelectedProduct = false;
          this.selectedProduct = [];
          this.model.status = 0;
          this.selectedStatus = this.model.status;
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
          let orderItemsData = creditInvoice.creditInvoiceItems;
          orderItemsData.forEach((element) => {
            delete element["credit_invoice_id"];
            delete element["id"];
            delete element["inventory"];
            delete element["supplied"];
            delete element["created_at"];
            delete element["updated_at"];
            delete element["multiple_doc_type"];
            delete element["calculated_discount"];
            if (element.item_code) {
              if (element["unit_price"] && !element["quantity"]) {
                element = Object.assign({}, { ...element, quantity: 1 });
              }
              this.deliveryItems.push(
                Object.assign({}, element, {
                  selectedProduct: {
                    item_id: element.item_id,
                    item_code: element.item_code,
                  },
                })
              );
              this.selectedProduct.push(
                Object.assign({}, element, {
                  selectedProduct: {
                    item_id: element.item_id,
                    item_code: element.item_code,
                  },
                })
              );
              this.model.total += parseFloat(element.total);
            }
          });

          if (
            creditInvoice.creditInvoiceItems.length ==
            this.selectedProduct.length
          ) {
            this.allSelectedProduct = true;
          }

          this.model.discount_percentage = creditInvoice.discount_percentage;
          this.model.vat_percentage = creditInvoice.vat_percentage;
          this.deliveryPriceCalculation();
        } else if (this.$route.query.returnCertificateId) {
          let customer = await this.$store.dispatch(
            "customer/GetCustomerById",
            this.$route.query.customerId
          );
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
          this.model.customer = customer;
          this.customer_id = this.$route.query.customerId;
          this.selectedCustomer = {
            id: this.model.customer?.id,
            name_1: this.model.customer?.name_1,
          };
          this.model.customer_id = this.model.customer.id;
          this.includeVatCharge = this.model.customer.vat_charge;
          this.customerOrderNoRequired =
            this.model.customer.customer_order_no_compulsory;
          let returnCertificate = await this.$store.dispatch(
            "returnCertificate/GetReturnCertificateById",
            this.$route.query.returnCertificateId
          );
          this.model.customer_order_no = returnCertificate.customer_order_no;
          this.model.authorized_dealer_no =
            returnCertificate.authorized_dealer_no;
          this.model.email = returnCertificate.email;
          this.model.address = returnCertificate.address;
          this.model.town = returnCertificate.town;
          this.model.country = returnCertificate.country;
          this.model.phone_1 = returnCertificate.phone_1;
          this.model.phone_2 = returnCertificate.phone_2;
          this.model.cellular = returnCertificate.cellular;
          this.model.fax = returnCertificate.fax;
          this.model.customer_name = returnCertificate.customer_name;
          if (returnCertificate.employee_id) {
            this.selectedEmployee = {
              id: returnCertificate.employee?.id,
              name: returnCertificate.employee?.name,
            };
            this.model.employee_id = returnCertificate.employee_id;
          }
          if (returnCertificate.contact_id) {
            this.selectedContact = {
              id: returnCertificate.contact?.id,
              name: returnCertificate.contact?.name,
            };
            this.model.contact_id = returnCertificate.contact_id;
          }
          this.model.remarks = returnCertificate.remarks;
          this.model.delivery_company =
            returnCertificate.return_certificate_company;
          if (this.model.deliveryItems && this.model.deliveryItems.length < 1) {
            this.deliveryItems = [
              {
                selectedProduct: null,
                unit_price: null,
                item_description: null,
                total: null,
              },
            ];
          } else {
            if (
              this.model.deliveryNoteItems &&
              this.model.deliveryNoteItems.length < 0
            ) {
              this.deliveryItems = [
                {
                  selectedProduct: null,
                  unit_price: null,
                  item_description: null,
                  total: null,
                },
              ];
            } else {
              this.deliveryItems = [];
            }
          }
          this.allSelectedProduct = false;
          this.selectedProduct = [];
          this.model.status = 0;
          this.selectedStatus = this.model.status;
          this.selectedGroup = null;
          this.selectedWarehouse = null;
          this.model.status_one = null;
          this.model.warehouse = null;
          let orderItemsData = returnCertificate.ReturnCertificateItems;
          orderItemsData.forEach((element) => {
            delete element["return_certificate_id"];
            delete element["delivery_note_id"];
            delete element["delivery_item_id"];
            delete element["inventory"];
            delete element["id"];
            delete element["remainingQuantity"];
            delete element["supplied"];
            delete element["created_at"];
            delete element["updated_at"];
            delete element["calculated_discount"];
            delete element["multiple_doc_type"];
            if (element.item_code) {
              if (element["unit_price"] && !element["quantity"]) {
                element = Object.assign({}, { ...element, quantity: 1 });
              }
              this.deliveryItems.push(
                Object.assign({}, element, {
                  selectedProduct: {
                    item_id: element.item_id,
                    item_code: element.item_code,
                  },
                })
              );
              this.selectedProduct.push(
                Object.assign({}, element, {
                  selectedProduct: {
                    item_id: element.item_id,
                    item_code: element.item_code,
                  },
                })
              );
              this.model.total += parseFloat(element.total);
            }
          });

          if (
            returnCertificate.ReturnCertificateItems.length ==
            this.selectedProduct.length
          ) {
            this.allSelectedProduct = true;
          }

          this.model.discount_percentage =
            returnCertificate.discount_percentage;
          this.model.vat_percentage = returnCertificate.vat_percentage;
          this.deliveryPriceCalculation();
        }
        this.model.year = new Date().getFullYear();
        this.model.delivery_date = this.profileById.date_format
          ? new Date().toISOString().substring(0, 10)
          : this.formatDateDDMMYYYY(new Date().toISOString().substring(0, 10));
      }
      this.$store.dispatch("profile/GetCompanyById");
      this.saveOnlyCategoryStatus = false;
      let tableNameValue = await getAuxiliaryZtableValueByName(13);
      if (Object.keys(tableNameValue).length > 0) {
        this.groupValue = tableNameValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
      }
    },
    changeCategoryStatus() {
      if (this.originModel.status === 1) {
        this.saveOnlyCategoryStatus = true;
      }
    },
    async documentNextAndPrevious(customerId = null) {
      if (this.deliveryId) {
        let docPagination = await this.$store.dispatch(
          "GetDocumentPagination",
          {
            type: "delivery_notes",
            customer_id: customerId,
            document_id: Number(this.deliveryId),
          }
        );
        this.nextId = docPagination.nextId ? docPagination.nextId : null;
        this.prevId = docPagination.prevId ? docPagination.prevId : null;
      }
    },
    async documentNextAndPreviousBlur(customerId = null) {
      let self = this;
      setTimeout(async () => {
        if (self.deliveryId) {
          let docPagination = await self.$store.dispatch(
            "GetDocumentPagination",
            {
              type: "delivery_notes",
              customer_id: customerId,
              document_id: Number(self.deliveryId),
            }
          );
          self.nextId = docPagination.nextId ? docPagination.nextId : null;
          self.prevId = docPagination.prevId ? docPagination.prevId : null;
        }
      }, 1000);
    },
    async infiniteScrollOnContact([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.contactLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async infiniteScrollOnEmployee([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.employeeLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    callBackFunction() {
      this.$router.push({
        name: "Delivery",
        query: {
          ...this.deliveryQuery,
        },
      });
    },
    copyDelivery() {
      this.copyAndCreateDialog = true;
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.customerLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    shouldShowCheckboxes() {
      if (
        this.$route.params.copiedModel &&
        this.selectedOrder &&
        !this.selectedProformaInvoice
      ) {
        return true;
      } else if (
        this.$route.query.orderId &&
        this.$route.query.customerId &&
        this.selectedOrder
      ) {
        return true;
      } else if (
        this.$route.params.copiedModel &&
        this.selectedProformaInvoice &&
        !this.selectedOrder
      ) {
        return true;
      } else if (
        this.$route.query.proformaId &&
        this.$route.query.customerId &&
        this.selectedProformaInvoice
      ) {
        return true;
      } else if (
        (this.$route.params.delivery_id && this.selectedProformaInvoice) ||
        (this.$route.params.delivery_id && this.selectedOrder)
      ) {
        return true;
      } else if (!this.$route.params.delivery_id && this.deliveryChanged) {
        return true;
      } else if (!this.$route.params.delivery_id && !this.selectedOrder) {
        return false;
      } else if (this.$route.params.delivery_id && this.deliveryChanged) {
        return true;
      } else if (this.$route.params.delivery_id && !this.deliveryChanged) {
        return false;
      }
    },
    redirectOnNextAndPrevious(id) {
      if (id) {
        this.$router.push({ path: "/delivery/DeliveryAction/" + id });
      } else {
        return false;
      }
    },
    redirectOnEditOrderPage(item) {
      if (item) {
        if (item.related_model_id) {
          this.$router.push({
            path: "/order/OrderAction/" + item.related_model_id,
          });
        } else {
          this.$router.push({ path: "/order/OrderAction/" + item });
        }
      } else {
        return false;
      }
    },
    redirectOnEditQuotationPage(item) {
      if (item) {
        if (item.related_model_id) {
          this.$router.push({
            path: "/quotation/QuotationAction/" + item.related_model_id,
          });
        } else {
          this.$router.push({ path: "/quotation/QuotationAction/" + item });
        }
      } else {
        return false;
      }
    },
    redirectOnEditInvoicePage(item) {
      if (item) {
        this.$router.push({
          path: "/invoice/InvoiceAction/" + item.related_model_id,
        });
      } else {
        return false;
      }
    },
    redirectOnEditCustomerPage() {
      if (this.selectedCustomer) {
        this.$router.push({
          path: "/customer/CustomerAction/" + this.selectedCustomer.id,
        });
      } else {
        return false;
      }
    },
    redirectOnEditEmployeePage() {
      if (this.selectedEmployee && this.selectedEmployee.id) {
        this.$router.push({
          path: "/employee/EmployeeAction/" + this.selectedEmployee.id,
        });
      } else {
        return false;
      }
    },
    redirectOnEditOrderClickPage() {
      if (this.selectedOrder) {
        this.$router.push({ path: "/order/OrderAction/" + this.selectedOrder });
      } else {
        return false;
      }
    },
    redirectOnEditProductPage(itemId) {
      if (itemId) {
        this.$router.push({ path: "/item/ItemAction/" + itemId });
      } else {
        return false;
      }
    },
    redirectOnEditProformaPage() {
      if (this.selectedProformaInvoice) {
        this.$router.push({
          path: "/proforma/ProformaAction/" + this.selectedProformaInvoice,
        });
      } else {
        return false;
      }
    },
    checkBoxEventCalculate(item) {
      if (
        (this.selectedProduct &&
          this.selectedProduct.some(
            (ab) => ab.order_item_id == item.order_item_id
          )) ||
        (this.selectedProduct &&
          this.selectedProduct.some(
            (ab) => ab.proforma_invoice_item_id == item.proforma_invoice_item_id
          ))
      ) {
        this.model.total =
          parseFloat(this.model.total) + parseFloat(item.total);
      } else {
        this.model.total =
          parseFloat(this.model.total) - parseFloat(item.total);
      }
      this.deliveryPriceCalculation();
    },
    numberFromCommas(x) {
      return x ? x.toString().replace(/,/g, "") : null;
    },
    numberWithCommas(x, type = "float") {
      return x
        ? type === "float"
          ? parseFloat(x)
              .toFixed(2)
              .toString()
              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
          : x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        : type === "float"
        ? (0).toFixed(2)
        : x;
    },
    selectAllOrderProduct() {
      this.selectedProduct = [];
      this.model.total = 0.0;
      if (this.allSelectedProduct) {
        for (let delivery in this.deliveryItems) {
          this.selectedProduct.push(this.deliveryItems[delivery]);
          this.model.total += parseFloat(this.deliveryItems[delivery].total);
          this.deliveryPriceCalculation();
        }
      } else {
        this.deliveryPriceCalculation();
      }
    },
    selectedDeliveryProduct() {
      if (this.deliveryItems.length == this.selectedProduct.length) {
        this.allSelectedProduct = true;
      } else this.allSelectedProduct = false;
    },
    onEmployeeChange(item) {
      if (item && typeof item === "object" && Object.keys(item).length > 0) {
        this.selectedEmployee = { id: item.id, name: item?.name };
        this.model.employee_id = item.id;
      } else {
        this.selectedEmployee = null;
        this.model.employee_id = null;
      }
    },
    onContactChange(item) {
      if (item && typeof item === "object" && Object.keys(item).length > 0) {
        this.selectedContact = { id: item.id, name: item?.name };
        this.model.contact_id = item.id;
      } else {
        this.model.contact_id = null;
      }
    },
    async onOrderChange(item) {
      if (item) {
        let order = await this.$store.dispatch("order/GetOrderById", item.id);
        this.deliveryChanged = true;
        this.selectedOrder = item.id;
        this.model.order_id = item.id;
        this.model.delivery_company = order.title;
        this.deliveryItems = [];
        this.allSelectedProduct = false;
        this.selectedProduct = [];
        order.orderItems.forEach((element) => {
          let orderItemId = element.id;
          delete element["created_at"];
          delete element["updated_at"];
          delete element["order_id"];
          delete element["quotation_item_id"];
          delete element["remainder"];
          delete element["id"];
          if (element.item_code) {
            this.deliveryItems.push(
              Object.assign({}, element, { order_item_id: orderItemId })
            );
          }
        });
        this.model.total = 0.0;
        this.model.discount_percentage = order.discount_percentage;
        this.model.discount = 0.0;
        this.model.after_discount = 0.0;
        this.model.vat = 0.0;
        this.model.grand_total = 0.0;
      }
    },
    openCustomerAdditionalDetailModel() {
      this.customerAdditionalDetail.authorized_dealer_no =
        this.model.authorized_dealer_no;
      this.customerAdditionalDetail.email = this.model.email;
      this.customerAdditionalDetail.country = this.model.country;
      this.customerAdditionalDetail.phone_1 = this.model.phone_1;
      this.customerAdditionalDetail.phone_2 = this.model.phone_2;
      this.customerAdditionalDetail.cellular = this.model.cellular;
      this.customerAdditionalDetail.fax = this.model.fax;
      this.customerDetailDialog = true;
    },
    async saveCustomerAdditionalDetail() {
      this.model.authorized_dealer_no =
        this.customerAdditionalDetail.authorized_dealer_no;
      this.model.email = this.customerAdditionalDetail.email;
      this.model.country = this.customerAdditionalDetail.country;
      this.model.phone_1 = this.customerAdditionalDetail.phone_1;
      this.model.phone_2 = this.customerAdditionalDetail.phone_2;
      this.model.cellular = this.customerAdditionalDetail.cellular;
      this.model.fax = this.customerAdditionalDetail.fax;
      if (this.$route.params.delivery_id) {
        this.isProgressCircular = true;
        await this.$store.dispatch("delivery/EditDelivery", {
          delivery_id: this.$route.params.delivery_id,
          delivery: {
            ...this.customerAdditionalDetail,
            main_number: 30,
            order_model_number: 15,
            quotation_model_number: 14,
          },
        });
        this.isProgressCircular = false;
      }
    },
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return (
        !v ||
        (v && String(v).length <= sizeValue) ||
        this.$t("formRules.lengthValidMessage", {
          fieldName: fieldValue,
          type: typeValue,
          size: sizeValue,
        })
      );
    },
    async onCustomerChange(customer) {
      if (
        customer &&
        typeof customer === "object" &&
        Object.keys(customer).length > 0
      ) {
        let customerModel = {};
        this.selectedCustomer = {
          id: customer.id,
          name_1: customer?.name_1,
          fixed_discount: customer?.fixed_discount,
        };
        this.selectedContact = null;
        this.model.contact_id = null;
        // this.model.order_id = null;
        // this.selectedOrder = null;
        this.model.customer_id = customer.id;
        this.customer_id = customer.id;
        this.includeVatCharge = customer.vat_charge;
        if (customer.vat_charge) {
          this.model.vat_percentage = this.profileById.vat_percentage;
        } else {
          this.model.vat_percentage = "";
          this.deliveryPriceCalculation();
        }
        this.customerOrderNoRequired = customer.customer_order_no_compulsory;
        if (customer.id) {
          await this.$store.dispatch("contact/GetContact", {
            page: 1,
            limit: 10,
            where_and:
              "record_type_number,record_type|" + customer.id + ",customers",
            order_by: "name|asc",
          });
        }
        if (this.profileById.occasional_customer_no !== String(customer.id)) {
          this.model.customer_name = customer?.name_1;
          if (customer.employee_id) {
            customer.employee_id
              ? await this.$store.dispatch(
                  "employee/GetEmployeeById",
                  customer.employee_id
                )
              : "";
            this.selectedEmployee = {
              id: this.employeeById.id,
              name: this.employeeById.name,
            };
            this.model.employee_id = customer.employee_id;
          } else {
            this.selectedEmployee = this.user.name;
          }
          customerModel.authorized_dealer_no = customer.authorized_dealer_no;
          customerModel.email = customer.email;
          customerModel.address = customer.address_1;
          customerModel.town = customer.town_1;
          customerModel.country = customer.country;
          customerModel.phone_1 = customer.phone_1;
          customerModel.phone_2 = customer.phone_2;
          customerModel.cellular = customer.cellular_1;
          customerModel.fax = customer.fax;
          this.deliveryPriceCalculation();
        } else {
          this.model.customer_name = "";
          customerModel.authorized_dealer_no = "";
          customerModel.email = "";
          customerModel.address = "";
          customerModel.town = "";
          customerModel.country = "";
          customerModel.phone_1 = "";
          customerModel.phone_2 = "";
          customerModel.cellular = "";
          customerModel.fax = "";
        }
        this.model = Object.assign({}, this.model, customerModel);
        if (this.model.status === 0) {
          this.$refs.form.validate();
        }
      } else {
        this.selectedCustomer = null;
        this.model.customer_id = null;
      }
    },
    itemPriceCalculation(index) {
      const decimalPoints = this.profileById.decimal_points || 2;
      let item = this.deliveryItems[index];
      let checkUnitPrice = item.unit_price;
      if (checkUnitPrice && this.$refs["unit_price" + index]) {
        const pos =
          this.$refs["unit_price" + index][0].$el.querySelector("input")
            .selectionStart + 1;
        if (
          checkUnitPrice.length !=
          this.numberWithCommas(
            this.numberFromCommas(item.unit_price),
            "noFloat"
          ).length
        ) {
          this.$nextTick(() => {
            this.$refs["unit_price" + index][0]?.$el
              ? (this.$refs["unit_price" + index][0].$el.querySelector(
                  "input"
                ).selectionEnd = pos)
              : "";
          });
        } else {
          this.$nextTick(() => {
            this.$refs["unit_price" + index][0]?.$el
              ? (this.$refs["unit_price" + index][0].$el.querySelector(
                  "input"
                ).selectionEnd = pos - 1)
              : "";
          });
        }
      }

      // price format of total price value
      let checkTotal = item.total;
      if (checkTotal && this.$refs["total_price" + index]) {
        const pos =
          this.$refs["total_price" + index][0].$el.querySelector("input")
            .selectionStart + 1;
        if (
          checkTotal.length !=
          this.numberWithCommas(this.numberFromCommas(item.total), "noFloat")
            .length
        ) {
          this.$nextTick(() => {
            this.$refs["total_price" + index][0]?.$el
              ? (this.$refs["total_price" + index][0].$el.querySelector(
                  "input"
                ).selectionEnd = pos)
              : "";
          });
        } else {
          this.$nextTick(() => {
            this.$refs["total_price" + index][0]?.$el
              ? (this.$refs["total_price" + index][0].$el.querySelector(
                  "input"
                ).selectionEnd = pos - 1)
              : "";
          });
        }
      }
      let unitPriceNumber = parseFloat(this.numberFromCommas(item.unit_price));
      this.deliveryItems[index].unit_price = this.numberWithCommas(
        unitPriceNumber.toFixed(decimalPoints),
        "noFloat"
      );

      this.deliveryItems[index].total = this.numberWithCommas(
        this.numberFromCommas(item.total),
        "noFloat"
      );

      this.$nextTick(() => {
        if (this.$refs.form) {
          if (
            this.$refs["unit_price" + index] &&
            this.$refs["unit_price" + index][0]
          ) {
            if (this.$refs["unit_price" + index][0].validate()) {
              item.unit_price = unitPriceNumber.toFixed(decimalPoints);
              console.log("item.unit_price: ", item.unit_price);
            }
          }

          if (
            this.$refs["total_price" + index] &&
            this.$refs["total_price" + index][0]
          ) {
            if (this.$refs["total_price" + index][0].validate()) {
              item.total = this.numberFromCommas(item.total);
            }
          }
        }
        if (parseFloat(item.unit_price)) {
          item.total =
            parseFloat(item.quantity) && parseFloat(item.unit_price)
              ? (
                  parseFloat(item.quantity) * parseFloat(item.unit_price)
                ).toFixed(2)
              : parseFloat(item.unit_price)
              ? parseFloat(item.unit_price).toFixed(2)
              : (0).toFixed(2);

          // item total calculation with discount
          item.total = parseFloat(item.line_discount)
            ? (
                parseFloat(item.total) -
                (parseFloat(item.total) * parseFloat(item.line_discount)) / 100
              ).toFixed(2)
            : parseFloat(item.total).toFixed(2);

          this.deliveryItems[index].unit_price = this.numberWithCommas(
            unitPriceNumber.toFixed(decimalPoints),
            "noFloat"
          );

          this.deliveryItems[index].total = this.numberWithCommas(
            this.numberFromCommas(item.total),
            "noFloat"
          );

          if (this.deliveryItems[index].unit_price && !item.quantity) {
            item.quantity = 1;
          }
        } else {
          this.deliveryItems[index].total = this.numberWithCommas(
            this.numberFromCommas(item.total),
            "noFloat"
          );
        }
        this.deliveryItems[index] = item;

        // total calculation on quotation
        this.deliveryPriceCalculation();
      });
    },
    deliveryPriceCalculation() {
      if (this.selectedOrder) {
        let self = this;
        if (this.orderById.including_vat) {
          this.model.total =
            this.selectedProduct.length > 0
              ? this.selectedProduct
                  .reduce(function (total, item) {
                    return parseFloat(self.numberFromCommas(item.total))
                      ? parseFloat(total) +
                          parseFloat(self.numberFromCommas(item.total))
                      : total + 0;
                  }, 0)
                  .toFixed(2) /
                (self.model.vat_percentage / 100 + 1)
              : 0;
        } else {
          this.model.total =
            this.selectedProduct.length > 0
              ? this.selectedProduct
                  .reduce(function (total, item) {
                    return parseFloat(self.numberFromCommas(item.total))
                      ? parseFloat(total) +
                          parseFloat(self.numberFromCommas(item.total))
                      : total + 0;
                  }, 0)
                  .toFixed(2)
              : 0;
        }

        // discount amount calculation
        this.model.discount_percentage = parseFloat(
          this.model.discount_percentage
        )
          ? parseFloat(this.model.discount_percentage).toFixed(2)
          : null;
        this.model.discount = parseFloat(this.model.discount_percentage)
          ? (
              (parseFloat(this.model.total) *
                parseFloat(this.model.discount_percentage)) /
              100
            ).toFixed(2)
          : (0).toFixed(2);

        // total amount after discount calculation
        this.model.after_discount = parseFloat(this.model.discount)
          ? (
              parseFloat(this.model.total) - parseFloat(this.model.discount)
            ).toFixed(2)
          : parseFloat(this.model.total).toFixed(2);

        this.model.vat_percentage = parseFloat(this.model.vat_percentage)
          ? parseFloat(this.model.vat_percentage)
          : null;
        this.model.vat =
          parseFloat(this.model.vat_percentage) && this.includeVatCharge
            ? (
                (parseFloat(this.model.after_discount) *
                  parseFloat(this.model.vat_percentage)) /
                100
              ).toFixed(2)
            : (0).toFixed(2);

        // grand total amount calculation
        this.model.grand_total = parseFloat(this.model.vat)
          ? (
              parseFloat(this.model.after_discount) + parseFloat(this.model.vat)
            ).toFixed(2)
          : parseFloat(this.model.after_discount).toFixed(2);

        // after vat grand total rounding if total rounding is true
        if (this.profileById.total_rounding) {
          let total = this.model.total;
          let discount = this.model.discount;
          let vatPercentage = this.model.vat_percentage;
          let grandTotal = parseInt(this.model.grand_total);
          let roundingDiff = this.model.grand_total - grandTotal;
          let findRoundNumber = parseFloat(
            roundingDiff / (1 + vatPercentage / 100)
          ).toFixed(2);
          if (findRoundNumber <= 0.5) {
            // calculate discount if findRoundNumber is lessthan 0.5
            this.model.discount = (
              parseFloat(discount) + parseFloat(findRoundNumber)
            ).toFixed(2);
            // calculate after_discount
            this.model.after_discount = (
              parseFloat(total) - parseFloat(this.model.discount)
            ).toFixed(2);
            // calculate vat
            this.model.vat = (
              parseFloat(grandTotal) - parseFloat(this.model.after_discount)
            ).toFixed(2);
            // calculate grand_total
            this.model.grand_total = parseFloat(grandTotal).toFixed(2);
          } else {
            let roundingDiff = parseFloat(
              (grandTotal + 1 - this.model.grand_total) * -1
            ).toFixed(2);
            // calculate discount if findRoundNumber is lessthan 0.5
            this.model.discount = (
              parseFloat(discount) +
              parseFloat(roundingDiff / (1 + vatPercentage / 100))
            ).toFixed(2);
            // calculate after_discount
            this.model.after_discount = (
              parseFloat(total) - parseFloat(this.model.discount)
            ).toFixed(2);
            // calculate vat
            this.model.vat = (
              parseFloat(grandTotal) +
              1 -
              parseFloat(this.model.after_discount)
            ).toFixed(2);
            // calculate grand_total
            this.model.grand_total = (parseFloat(grandTotal) + 1).toFixed(2);
          }
        }
      } else {
        let self = this;
        if (this.selectedProformaInvoice) {
          if (this.proformaById.including_vat) {
            this.model.total =
              this.selectedProduct.length > 0
                ? this.selectedProduct
                    .reduce(function (total, item) {
                      return parseFloat(self.numberFromCommas(item.total))
                        ? parseFloat(total) +
                            parseFloat(self.numberFromCommas(item.total))
                        : total + 0;
                    }, 0)
                    .toFixed(2) /
                  (self.model.vat_percentage / 100 + 1)
                : 0;
          } else {
            this.model.total =
              this.selectedProduct.length > 0
                ? this.selectedProduct
                    .reduce(function (total, item) {
                      return parseFloat(self.numberFromCommas(item.total))
                        ? parseFloat(total) +
                            parseFloat(self.numberFromCommas(item.total))
                        : total + 0;
                    }, 0)
                    .toFixed(2)
                : 0;
          }
        } else {
          if (!this.$route.params.copiedModel) {
            if (
              (!this.deliveryId &&
                this.profileById.delivery &&
                this.includeVatCharge) ||
              (this.deliveryId && this.model.including_vat)
            ) {
              this.model.total =
                this.deliveryItems.length > 0
                  ? this.deliveryItems
                      .reduce(function (total, item) {
                        return parseFloat(self.numberFromCommas(item.total))
                          ? parseFloat(total) +
                              parseFloat(self.numberFromCommas(item.total))
                          : total + 0;
                      }, 0)
                      .toFixed(2) /
                    (self.model.vat_percentage / 100 + 1)
                  : 0;
            } else if (
              this.$route.query.quotationId ||
              this.$route.query.orderId ||
              this.$route.query.proformaId ||
              this.$route.query.invoiceId ||
              this.$route.query.receiptTaxInvoiceId ||
              this.$route.query.creditInvoiceId ||
              this.$route.query.returnCertificateId
            ) {
              if (
                this.quotationById.including_vat ||
                this.proformaById.including_vat ||
                this.orderById.including_vat ||
                this.invoiceById.including_vat ||
                this.receiptTaxInvoiceById.including_vat ||
                this.creditInvoiceById.including_vat ||
                this.returnCertificateById.including_vat
              ) {
                this.model.total =
                  this.deliveryItems.length > 0
                    ? this.deliveryItems
                        .reduce(function (total, item) {
                          return parseFloat(self.numberFromCommas(item.total))
                            ? parseFloat(total) +
                                parseFloat(self.numberFromCommas(item.total))
                            : total + 0;
                        }, 0)
                        .toFixed(2) /
                      (self.model.vat_percentage / 100 + 1)
                    : 0;
              } else {
                this.model.total =
                  this.deliveryItems.length > 0
                    ? this.deliveryItems
                        .reduce(function (total, item) {
                          return parseFloat(self.numberFromCommas(item.total))
                            ? parseFloat(total) +
                                parseFloat(self.numberFromCommas(item.total))
                            : total + 0;
                        }, 0)
                        .toFixed(2)
                    : 0;
              }
            } else {
              this.model.total =
                this.deliveryItems.length > 0
                  ? this.deliveryItems
                      .reduce(function (total, item) {
                        return parseFloat(self.numberFromCommas(item.total))
                          ? parseFloat(self.numberFromCommas(item.total))
                          : total + 0;
                      }, 0)
                      .toFixed(2)
                  : 0;
            }
          } else {
            if (this.$route?.params?.copiedModel?.including_vat) {
              this.model.total =
                this.deliveryItems.length > 0
                  ? this.deliveryItems
                      .reduce(function (total, item) {
                        return parseFloat(self.numberFromCommas(item.total))
                          ? parseFloat(total) +
                              parseFloat(self.numberFromCommas(item.total))
                          : total + 0;
                      }, 0)
                      .toFixed(2) /
                    (self.model.vat_percentage / 100 + 1)
                  : 0;
            } else {
              this.model.total =
                this.deliveryItems.length > 0
                  ? this.deliveryItems
                      .reduce(function (total, item) {
                        return parseFloat(self.numberFromCommas(item.total))
                          ? parseFloat(total) +
                              parseFloat(self.numberFromCommas(item.total))
                          : total + 0;
                      }, 0)
                      .toFixed(2)
                  : 0;
            }
          }
        }

        // discount amount calculation
        this.model.discount_percentage = parseFloat(
          this.model.discount_percentage
        )
          ? parseFloat(this.model.discount_percentage).toFixed(2)
          : null;
        this.model.discount = parseFloat(this.model.discount_percentage)
          ? (
              (parseFloat(this.model.total) *
                parseFloat(this.model.discount_percentage)) /
              100
            ).toFixed(2)
          : (0).toFixed(2);

        // total amount after discount calculation
        this.model.after_discount = parseFloat(this.model.discount)
          ? (
              parseFloat(this.model.total) - parseFloat(this.model.discount)
            ).toFixed(2)
          : parseFloat(this.model.total).toFixed(2);

        this.model.vat_percentage = parseFloat(this.model.vat_percentage)
          ? parseFloat(this.model.vat_percentage)
          : null;
        this.model.vat =
          parseFloat(this.model.vat_percentage) && this.includeVatCharge
            ? (
                (parseFloat(this.model.after_discount) *
                  parseFloat(this.model.vat_percentage)) /
                100
              ).toFixed(2)
            : (0).toFixed(2);

        // grand total amount calculation
        this.model.grand_total = parseFloat(this.model.vat)
          ? (
              parseFloat(this.model.after_discount) + parseFloat(this.model.vat)
            ).toFixed(2)
          : parseFloat(this.model.after_discount).toFixed(2);

        // after vat grand total rounding if total rounding is true
        if (this.profileById.total_rounding) {
          let total = this.model.total;
          let discount = this.model.discount;
          let vatPercentage = this.model.vat_percentage;
          let grandTotal = parseInt(this.model.grand_total);
          let roundingDiff = this.model.grand_total - grandTotal;
          let findRoundNumber = parseFloat(
            roundingDiff / (1 + vatPercentage / 100)
          ).toFixed(2);
          if (findRoundNumber <= 0.5) {
            // calculate discount if findRoundNumber is lessthan 0.5
            this.model.discount = (
              parseFloat(discount) + parseFloat(findRoundNumber)
            ).toFixed(2);
            // calculate after_discount
            this.model.after_discount = (
              parseFloat(total) - parseFloat(this.model.discount)
            ).toFixed(2);
            // calculate vat
            this.model.vat = (
              parseFloat(grandTotal) - parseFloat(this.model.after_discount)
            ).toFixed(2);
            // calculate grand_total
            this.model.grand_total = parseFloat(grandTotal).toFixed(2);
          } else {
            let roundingDiff = parseFloat(
              (grandTotal + 1 - this.model.grand_total) * -1
            ).toFixed(2);
            // calculate discount if findRoundNumber is lessthan 0.5
            this.model.discount = (
              parseFloat(discount) +
              parseFloat(roundingDiff / (1 + vatPercentage / 100))
            ).toFixed(2);
            // calculate after_discount
            this.model.after_discount = (
              parseFloat(total) - parseFloat(this.model.discount)
            ).toFixed(2);
            // calculate vat
            this.model.vat = (
              parseFloat(grandTotal) +
              1 -
              parseFloat(this.model.after_discount)
            ).toFixed(2);
            // calculate grand_total
            this.model.grand_total = (parseFloat(grandTotal) + 1).toFixed(2);
          }
        }
      }
    },
    addRow() {
      this.deliveryItems.push({
        selectedProduct: null,
        unit_price: null,
        item_description: null,
        total: null,
      });
      // scroll on new added row
      let newRow = document.querySelector(".newRow");
      let wrapper = document.querySelector(
        ".item-table div.v-data-table__wrapper"
      );
      this.$vuetify.goTo(newRow, { container: wrapper });
    },
    copyData(i) {
      delete this.deliveryItems[i].id;
      delete this.deliveryItems[i].delivery_note_id;
      delete this.deliveryItems[i].created_at;
      delete this.deliveryItems[i].updated_at;
      delete this.deliveryItems[i].year;
      delete this.deliveryItems[i].line_number;
      delete this.deliveryItems[i].order_item_id;
      delete this.deliveryItems[i].proforma_invoice_item_id;
      delete this.deliveryItems[i].supplied;
      this.deliveryItems.push({ ...this.deliveryItems[i] });
    },
    deleteRow(index) {
      this.selectedProduct = this.selectedProduct.filter(
        (item) => item.index !== index
      );
      this.selectedProduct.splice(index, 1);
      this.deliveryItems.splice(index, 1);
      this.deliveryPriceCalculation();
    },
    onOrderClear() {
      this.model.total = 0.0;
      this.model.after_discount = 0.0;
      this.model.vat = 0.0;
      this.model.grand_total = 0.0;
      this.model.discount = 0.0;
      this.deliveryItems = [
        {
          selectedProduct: null,
          unit_price: null,
          item_description: null,
          total: null,
        },
      ];
    },
    async onProductChange(item, index, type) {
      if (item && type === "dropdown") {
        this.deliveryItems[index].selectedProduct = {
          item_id: item.id,
          item_code: item.item_code,
        };

        if (!item?.customerPrice) {
          this.deliveryItems[index].unit_price = item.unit_price;
        }

        this.deliveryItems[index].item_id = item.id;
        this.deliveryItems[index].item_code = item.item_code;
        this.deliveryItems[index].item_description = item.item_description;

        if (item.quantity) {
          if (this.selectedCustomer?.fixed_discount) {
            this.deliveryItems[index].line_discount =
              this.selectedCustomer?.fixed_discount;
          } else if (item.customerDiscount) {
            this.deliveryItems[index].line_discount = item.customerDiscount;
          } else if (item.customerPrice) {
            this.deliveryItems[index].unit_price = item.customerPrice;
          } else if (item.priceListDiscount) {
            this.deliveryItems[index].line_discount = item.priceListDiscount;
          } else if (item.fixed_discount) {
            this.deliveryItems[index].line_discount = item.fixed_discount;
          } else {
            this.deliveryItems[index].line_discount = null;
          }
        } else {
          this.deliveryItems[index].line_discount = null;
        }

        this.selectedProduct[index] = this.deliveryItems[index];
        this.$refs.form.validate();
        this.itemPriceCalculation(index);
      } else {
        if (item && Object.keys(item).length > 0) {
          await this.$store
            .dispatch("product/GetItemDiscountOrPrice", {
              customer_id: this.selectedCustomer.id,
              item_id: item.id,
            })
            .then(async (res) => {
              this.itemDiscount = await res;
            });

          this.deliveryItems[index].selectedProduct = {
            item_id: item.id,
            item_code: item.item_code,
          };

          if (!this.itemDiscount && !this.itemDiscount.customerPrice) {
            this.deliveryItems[index].unit_price = item.sale_price;
          }

          this.deliveryItems[index].item_id = item.id;
          this.deliveryItems[index].item_code = item.item_code;
          this.deliveryItems[index].item_description = item.description;

          if (this.selectedCustomer?.fixed_discount) {
            this.deliveryItems[index].line_discount =
              this.selectedCustomer?.fixed_discount;
          } else if (this.itemDiscount && this.itemDiscount.customerDiscount) {
            this.deliveryItems[index].line_discount =
              this.itemDiscount.customerDiscount;
          } else if (this.itemDiscount && this.itemDiscount.customerPrice) {
            this.deliveryItems[index].unit_price =
              this.itemDiscount.customerPrice;
          } else if (this.itemDiscount && this.itemDiscount.priceListDiscount) {
            this.deliveryItems[index].line_discount =
              this.itemDiscount.priceListDiscount;
          } else {
            this.deliveryItems[index].line_discount = null;
          }

          this.selectedProduct[index] = this.deliveryItems[index];
          this.$refs.form.validate();
          this.itemPriceCalculation(index);
        } else {
          this.deliveryItems[index].selectedProduct = null;
          this.deliveryItems[index].unit_price = null;
          this.deliveryItems[index].item_id = null;
          this.deliveryItems[index].item_code = null;
          this.deliveryItems[index].item_description = null;
          this.deliveryItems[index].line_discount = null;
          this.selectedProduct[index] = this.deliveryItems[index];
          this.$refs.form.validate();
          this.itemPriceCalculation(index);
        }
      }
    },
    saveStatusEventWithDelivery() {
      this.fromSaveButton
        ? this.saveDelivery()
        : this.saveDeliveryCreateInvoice();
    },
    async saveDelivery() {
      if (this.$refs.form.validate()) {
        // open confirmation dialog for change order status
        // for update order status as closed
        if (this.model.order_id && this.setOrderStatus == null) {
          let totalSelectedItemFromOrder = this.selectedProduct.filter(
            (item) => item.order_item_id
          );
          let orderData = await this.$store.dispatch(
            "order/GetOrderById",
            this.model.order_id
          );
          if (
            orderData.orderItems.length !== totalSelectedItemFromOrder.length
          ) {
            this.fromSaveButton = true;
            this.confirmationDialog = true;
          }
        }
        if (!this.confirmationDialog) {
          this.loading = true;
          let data;
          let deliveryItems;
          if (!this.selectedOrder && !this.selectedProformaInvoice) {
            deliveryItems = this.deliveryItems.filter((item) => {
              if (item.id || item.item_id) {
                delete item["selectedProduct"];
                delete item["remainder"];
                delete item["document_number"];
                delete item["document_date"];
                delete item["customer_order_no"];
                delete item["title"];
                item.quantity = item.quantity === "" ? null : item.quantity;
                item.line_discount =
                  item.line_discount === "" ? null : item.line_discount;
                item.unit_price =
                  this.numberFromCommas(item.unit_price) === ""
                    ? null
                    : this.numberFromCommas(item.unit_price);
                item.total =
                  this.numberFromCommas(item.total) === ""
                    ? null
                    : this.numberFromCommas(item.total);
                return item;
              }
            });
          } else {
            deliveryItems = this.selectedProduct.filter((item) => {
              if (item.id || item.item_id) {
                delete item["selectedProduct"];
                delete item["remainder"];
                item.quantity = item.quantity === "" ? null : item.quantity;
                item.line_discount =
                  item.line_discount === "" ? null : item.line_discount;
                item.unit_price =
                  this.numberFromCommas(item.unit_price) === ""
                    ? null
                    : this.numberFromCommas(item.unit_price);
                item.total =
                  this.numberFromCommas(item.total) === ""
                    ? null
                    : this.numberFromCommas(item.total);
                return item;
              }
            });
          }
          this.model.data = deliveryItems;
          if (
            this.selectedStatus &&
            Object.keys(this.selectedStatus).length > 0
          ) {
            this.model.status = this.selectedStatus.id;
          } else {
            this.model.status = this.selectedStatus;
          }
          if (
            this.selectedGroup &&
            Object.keys(this.selectedGroup).length > 0
          ) {
            this.model.status_one = this.selectedGroup.id;
          }
          if (
            this.selectedWarehouse &&
            Object.keys(this.selectedWarehouse).length > 0
          ) {
            this.model.warehouse = this.selectedWarehouse.id;
          }
          if (this.model.order_id && !this.$route.params.copiedModel) {
            this.model.including_vat = this.orderById.including_vat ? 1 : 0;
          } else if (
            this.model.proforma_invoice_id &&
            !this.$route.params.copiedModel &&
            !this.model.id
          ) {
            this.model.including_vat = this.proformaById.including_vat ? 1 : 0;
          } else if (!this.$route.params.copiedModel && !this.model.id) {
            this.model.including_vat = this.profileById.delivery ? 1 : 0;
          }
          if (this.$route.params.copiedModel && !this.model.id) {
            this.model.including_vat = this.$route?.params?.copiedModel
              ?.including_vat
              ? 1
              : 0;
          }
          if (!this.model.id) {
            if (this.$route.query.quotationId) {
              this.model.including_vat = this.quotationById.including_vat
                ? 1
                : 0;
            } else if (this.$route.query.proformaId) {
              this.model.including_vat = this.proformaById.including_vat
                ? 1
                : 0;
            } else if (this.$route.query.creditInvoiceId) {
              this.model.including_vat = this.creditInvoiceById.including_vat
                ? 1
                : 0;
            } else if (this.$route.query.invoiceId) {
              this.model.including_vat = this.invoiceById.including_vat ? 1 : 0;
            } else if (this.$route.query.receiptTaxInvoiceId) {
              this.model.including_vat = this.receiptTaxInvoiceById
                .including_vat
                ? 1
                : 0;
            } else if (this.$route.query.orderId) {
              this.model.including_vat = this.orderById.including_vat ? 1 : 0;
            } else if (this.$route.query.returnCertificateId) {
              this.model.including_vat = this.returnCertificateById
                .including_vat
                ? 1
                : 0;
            }
          }
          if (this.moreDocumentNumber && !this.deliveryId) {
            this.model.doc_series_number = this.model.doc_series_number.id;
          }
          if (this.$route.query.type !== "copy-create") {
            this.model.order_id = this.selectedOrder;
          }
          if (this.$route.query.type !== "copy-create") {
            this.model.proforma_invoice_id = this.selectedProformaInvoice;
          }
          this.model.main_number = 30;
          this.model.order_model_number = 15;
          this.model.quotation_model_number = 14;

          if (this.model.data.length > 0) {
            if (
              this.selectedContact &&
              typeof this.selectedContact === "string"
            ) {
              if (this.model.customer_id) {
                let contactData = await this.$store.dispatch(
                  "contact/SetContact",
                  {
                    name: this.selectedContact,
                    record_type: "customers",
                    record_type_number: this.model.customer_id,
                  }
                );
                this.model.contact_id = contactData.data.id;
              }
            }
            if (this.$route.params.delivery_id) {
              // save only update model value using changedFormData from utils
              let saveData = changedFormData(this.model, this.originModel);
              if (this.model.order_id) {
                saveData.order_id = this.model.order_id;
              }
              if (saveData.delivery_date) {
                saveData.delivery_date = parseDateYYYYMMDD(
                  saveData.delivery_date
                );
              }
              saveData.delivery_date = parseDateYYYYMMDD(
                this.model.delivery_date
              );
              console.log(
                "this.model.delivery_date: ",
                this.model.delivery_date
              );
              this.isProgressCircular = true;
              data = await this.$store
                .dispatch("delivery/EditDelivery", {
                  delivery_id: this.$route.params.delivery_id,
                  delivery: saveData,
                })
                .catch((e) => {
                  this.loading = false;
                  console.log(e);
                });
              if (data) {
                this.$refs.form.reset();
                this.loading = false;
                await this.onMounteUpdated();
              }
              this.isProgressCircular = false;
            } else if (this.$route.params.copiedModel) {
              let copyModel = this.model;
              delete copyModel.contact;
              delete copyModel.employee;
              delete copyModel.customer;
              delete copyModel.created_at;
              delete copyModel.id;
              delete copyModel.delivery_no;
              delete copyModel.deliveryNoteItems;
              delete copyModel.quotation;
              delete copyModel.order;
              delete copyModel.updated_at;
              copyModel.data.forEach((item) => {
                delete item.id;
                delete item.delivery_note_id;
                delete item.created_at;
                delete item.updated_at;
                delete item.line_number;
                delete item.order_item_id;
                delete item.remainder;
              });
              copyModel.delivery_date = parseDateYYYYMMDD(
                copyModel.delivery_date
              );
              data = await this.$store
                .dispatch("delivery/SetDelivery", copyModel)
                .catch((e) => {
                  this.loading = false;
                  console.log(e);
                });
              if (data) {
                this.$refs.form.reset();
                this.loading = false;
                if (this.accessRight.includes("edit")) {
                  await this.$router
                    .push("/delivery/editDelivery/" + data.data.id)
                    .catch(() => {});
                } else {
                  this.callBackFunction();
                }
              }
            } else {
              this.model.data.forEach((ele) => {
                delete ele.remainingQuantity;
              });
              try {
                let copyData = this.model;
                copyData.delivery_date = parseDateYYYYMMDD(
                  copyData.delivery_date
                );
                data = await this.$store.dispatch(
                  "delivery/SetDelivery",
                  copyData
                );
                if (data) {
                  if (this.model.proforma_invoice_id) {
                    if (this.proformaById.status == 0) {
                      await this.$store.dispatch("proforma/EditProforma", {
                        proforma_id: this.model.proforma_invoice_id,
                        proforma: { status: 1, balance: 0 },
                      });
                    }
                  }
                  // for update order status as closed
                  if (this.model.order_id) {
                    let totalSelectedItemFromOrder =
                      this.selectedProduct.filter((item) => item.order_item_id);
                    let orderData = await this.$store.dispatch(
                      "order/GetOrderById",
                      this.model.order_id
                    );
                    let orderStatus;
                    if (
                      orderData.orderItems.length !==
                      totalSelectedItemFromOrder.length
                    ) {
                      orderStatus = this.setOrderStatus;
                    } else {
                      orderStatus = 1;
                    }
                    await this.$store
                      .dispatch("order/EditOrder", {
                        order_id: this.model.order_id,
                        order: {
                          status: orderStatus,
                          main_number: 15,
                          related_number: 14,
                          quotation_id: orderData.quotation_id,
                        },
                      })
                      .catch((e) => {
                        this.loading = false;
                        console.log(e);
                      });
                  }
                }
              } catch (error) {
                this.loading = false;
                console.log(error);
              }
              if (data) {
                this.$refs.form.reset();
                this.loading = false;
                if (this.accessRight.includes("edit")) {
                  await this.$router
                    .push("/delivery/editDelivery/" + data.data.id)
                    .catch(() => {});
                } else {
                  this.callBackFunction();
                }
              }
            }
          } else {
            this.model.doc_series_number = null;
            this.message = "formRules.productItemAlert";
            this.alert = true;
            this.loading = false;
          }
        }
      }
      // else {
      //   this.message = "formRules.formAlert";
      //   this.alert = true;
      // }
    },
    async saveDeliveryCreateInvoice(type) {
      if (
        type &&
        this.model &&
        this.model.deliveryNoteItems &&
        this.model.deliveryNoteItems.every((ele) => ele.remainingQuantity === 0)
      ) {
        this.suppliedDialogBox = true;
      } else {
        try {
          await this.$store.dispatch("quotation/CheckRemainingQty", {
            data: this.model.deliveryNoteItems,
            document_type:
              type === "ReturnCertificate"
                ? "Return_Certificate"
                : type === "Invoice"
                ? "Invoice"
                : undefined,
          });
          this.suppliedDialogBox = false;
          let data;
          if (this.$refs.form.validate() && !this.isFieldReadable) {
            // open confirmation dialog for change order status
            // for update order status as closed
            if (this.model.order_id && this.setOrderStatus == null) {
              let totalSelectedItemFromOrder = this.selectedProduct.filter(
                (item) => item.order_item_id
              );
              let orderData = await this.$store.dispatch(
                "order/GetOrderById",
                this.model.order_id
              );
              if (
                orderData.orderItems.length !==
                totalSelectedItemFromOrder.length
              ) {
                this.fromSaveButton = false;
                this.confirmationDialog = true;
              }
            }
            if (!this.confirmationDialog) {
              this.loading = true;
              let deliveryItems;
              if (!this.selectedOrder && !this.selectedProformaInvoice) {
                deliveryItems = this.deliveryItems.filter((item) => {
                  if (item.id || item.item_id) {
                    delete item["selectedProduct"];
                    delete item["remainder"];
                    item.quantity = item.quantity === "" ? null : item.quantity;
                    item.line_discount =
                      item.line_discount === "" ? null : item.line_discount;
                    item.unit_price =
                      this.numberFromCommas(item.unit_price) === ""
                        ? null
                        : this.numberFromCommas(item.unit_price);
                    item.total =
                      this.numberFromCommas(item.total) === ""
                        ? null
                        : this.numberFromCommas(item.total);
                    return item;
                  }
                });
              } else {
                deliveryItems = this.selectedProduct.filter((item) => {
                  if (item.id || item.item_id) {
                    delete item["selectedProduct"];
                    delete item["remainder"];
                    item.quantity = item.quantity === "" ? null : item.quantity;
                    item.line_discount =
                      item.line_discount === "" ? null : item.line_discount;
                    item.unit_price =
                      this.numberFromCommas(item.unit_price) === ""
                        ? null
                        : this.numberFromCommas(item.unit_price);
                    item.total =
                      this.numberFromCommas(item.total) === ""
                        ? null
                        : this.numberFromCommas(item.total);
                    return item;
                  }
                });
              }
              this.model.data = deliveryItems;
              if (
                this.selectedStatus &&
                Object.keys(this.selectedStatus).length > 0
              ) {
                this.model.status = this.selectedStatus.id;
              } else {
                this.model.status = this.selectedStatus;
              }
              if (
                this.selectedGroup &&
                Object.keys(this.selectedGroup).length > 0
              ) {
                this.model.status_one = this.selectedGroup.id;
              }
              if (
                this.selectedWarehouse &&
                Object.keys(this.selectedWarehouse).length > 0
              ) {
                this.model.warehouse = this.selectedWarehouse.id;
              }
              this.model.order_id = this.selectedOrder;
              if (this.$route.query.type !== "copy-create") {
                this.model.proforma_invoice_id = this.selectedProformaInvoice;
              }
              this.model.main_number = 30;
              this.model.order_model_number = 15;
              this.model.quotation_model_number = 14;

              if (this.model.data.length > 0) {
                if (
                  this.selectedContact &&
                  typeof this.selectedContact === "string"
                ) {
                  if (this.model.customer_id) {
                    let contactData = await this.$store.dispatch(
                      "contact/SetContact",
                      {
                        name: this.selectedContact,
                        record_type: "customers",
                        record_type_number: this.model.customer_id,
                      }
                    );
                    this.model.contact_id = contactData.data.id;
                  }
                }
                // for update order status as closed
                if (this.model.order_id) {
                  let totalSelectedItemFromOrder = this.selectedProduct.filter(
                    (item) => item.order_item_id
                  );
                  let orderData = await this.$store.dispatch(
                    "order/GetOrderById",
                    this.model.order_id
                  );
                  let orderStatus;
                  if (
                    orderData.orderItems.length !==
                    totalSelectedItemFromOrder.length
                  ) {
                    orderStatus = this.setOrderStatus;
                  } else {
                    orderStatus = 1;
                  }
                  await this.$store
                    .dispatch("order/EditOrder", {
                      order_id: this.model.order_id,
                      order: {
                        status: orderStatus,
                        main_number: 15,
                        related_number: 14,
                        quotation_id: orderData.quotation_id,
                      },
                    })
                    .catch((e) => {
                      this.loading = false;
                      console.log(e);
                    });
                }

                if (this.$route.query.type !== "copy-create") {
                  if (this.model.proforma_invoice_id) {
                    if (this.proformaById.status == 0) {
                      await this.$store.dispatch("proforma/EditProforma", {
                        proforma_id: this.model.proforma_invoice_id,
                        proforma: { status: 1, balance: 0 },
                      });
                    }
                  }
                }

                if (this.$route.params.delivery_id) {
                  // save only update model value using changedFormData from utils
                  let saveData = changedFormData(this.model, this.originModel);
                  if (this.model.order_id) {
                    saveData.order_id = this.model.order_id;
                  }
                  this.isProgressCircular = true;
                  data = await this.$store
                    .dispatch("delivery/EditDelivery", {
                      delivery_id: this.$route.params.delivery_id,
                      delivery: saveData,
                    })
                    .catch((e) => {
                      this.loading = false;
                      console.log(e);
                    });
                  this.isProgressCircular = false;
                } else {
                  data = await this.$store
                    .dispatch("delivery/SetDelivery", this.model)
                    .catch((e) => {
                      this.loading = false;
                      console.log(e);
                    });
                }
                if (data) {
                  this.$refs.form.reset();
                  this.$router.push("/delivery");
                }
              } else {
                this.message = "formRules.productItemAlert";
                this.alert = true;
                this.loading = false;
              }
              if (type === "Invoice") {
                this.$router.push({
                  path: "/addInvoice",
                  query: {
                    deliveryId: JSON.parse(data.config.data).data[0]
                      .delivery_note_id,
                    customerId: this.model.customer_id,
                  },
                });
              } else {
                this.$router.push({
                  path: "/addReturnCertificate",
                  query: {
                    deliveryId: JSON.parse(data.config.data).data[0]
                      .delivery_note_id,
                    customerId: this.model.customer_id,
                  },
                });
              }
            }
          } else if (this.isFieldReadable) {
            if (type === "Invoice") {
              this.$router.push({
                path: "/addInvoice",
                query: {
                  deliveryId: this.$route.params.delivery_id,
                  customerId: this.model.customer_id,
                },
              });
            } else {
              this.$router.push({
                path: "/addReturnCertificate",
                query: {
                  deliveryId: this.$route.params.delivery_id,
                  customerId: this.model.customer_id,
                },
              });
            }
          } else {
            this.message = "formRules.formAlert";
            this.alert = true;
          }
        } catch (error) {
          console.log(error, "error");
          this.suppliedDialogBox = true;
        }
      }
    },
    suppliedQty() {
      this.remainingSuppliedDialogBox = true;
    },
    async deleteItem() {
      this.isProgressCircular = true;
      await this.$store.dispatch(
        "delivery/DeleteDeliveryItemById",
        this.$route.params.delivery_id
      );
      this.$router
        .push("/delivery/editDelivery/" + this.$route.params.delivery_id)
        .catch(() => {});
      this.model.status = 2;
      this.originModel.status = 2;
      this.selectedStatus = 2;
      this.isProgressCircular = false;
    },
  },
};
</script>
<style scoped>
.hebrewposition {
  position: absolute;
  right: 112px;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
}
::v-deep .yourRowClass.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
